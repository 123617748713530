import React, { useEffect, useState, useContext } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Store } from "../Store";
import { getError } from "../utils";
import axios from "axios";
import { BiCart } from "react-icons/bi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ProductScreen from "./ProductScreen";
import CartScreen from "./CartScreen";
import SearchScreen from "./SearchScreen";
import HomeScreen from "./HomeScreen";

function ShopLayout() {
  const { state } = useContext(Store);
  const { cart } = state;
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  console.log(categories,'categories')
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 583);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 583);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(process.env.REACT_APP_API_URL + `/catagories`,
       { headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        }}
        );
        setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    fetchCategories();
  }, []);

  const closeSideBar = () => setSidebarIsOpen(false);

  return (
    <div className={` bg-[#F3FFDB] min-h-screen flex `}>
      <ToastContainer position="bottom-center" limit={1} />

      <div
        className={` top-0 left-0 ${
          isLargeScreen
            ? "w-[200px]  "
            : sidebarIsOpen
            ? "w-[100%]"
            : "w-[0px] "
        } ${
          isLargeScreen ? " sticky" : "fixed z-20  bg-[#F3FFDB]"
        }  h-screen flex-none    `}
      >
        <div className="flex flex-col pt-[7rem] pb-[3rem] justify-between items-center w-full h-full relative">
          {!isLargeScreen && (
            <div
              className={`absolute bg-red-500  z-50 h-8 w-5 flex items-center justify-center  ${
                sidebarIsOpen ? "rounded-l-lg right-0" : "rounded-r-lg -right-5"
              }`}
            >
              {" "}
              <button
                className="bg-f3ffdb border-none"
                onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
              >
                {sidebarIsOpen ? (
                  <IoIosArrowBack style={{ color: "black" }} />
                ) : (
                  <IoIosArrowForward style={{ color: "black" }} />
                )}
              </button>
            </div>
          )}
          <div
            className={`flex flex-col pt-5 items-end ${
              isLargeScreen ? "" : sidebarIsOpen ? "" : "w-0 overflow-hidden"
            }`}
          >
            <strong className={` text-green text-2xl mb-4`}>
              Product Types 
            </strong>
            { 
              
              categories?.data?.map((x) => (
                <div key={x.id} className={` `}>
                  <Link
                    onClick={() => {
                      window.scrollTo(0, 0);
                      closeSideBar();
                    }}
                    to={{
                      pathname: "search",
                      search: `category=${x?.attributes?.name}`,
                    }}
                    className={` text-center hover:bg-green rounded-lg my-3 p-3 text-lg bg-greendark text-white  `}
                  >
                    {x?.attributes?.name}
                  </Link>
                  <br />
                  <br />
                </div>
              ))}
          </div>
          <div
            className={`${
              isLargeScreen ? "" : sidebarIsOpen ? "" : "w-0 overflow-hidden"
            }`}
            onClick={closeSideBar}
          >
            <strong className={` text=[1.6rem] text-green`}>
              Shopping Cart
            </strong>
            <div className="flex">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to="cart"
                className=" flex items-center"
              >
                <BiCart size={44} style={{ color: "#00a166" }} />{" "}
                {cart.cartItems.length > 0 && (
                  <div className="bg-red-500 rounded-full px-1 top-0 right-4 text-[0.8rem] text-white ">
                    {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
                  </div>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <main className={` mt-[6rem] w-full ${sidebarIsOpen ? "w-[0]" : " "}`}>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/product/:slug" element={<ProductScreen />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/search" element={<SearchScreen />} />
        </Routes>
      </main>
    </div>
  );
}

export default ShopLayout;
