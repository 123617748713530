import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const Login = ({ setIsLogin }) => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const products = location.state?.products || [];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { email, password } = formData;
    
        if (email && password) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/accounts`, {
                    params: { email, password },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                    },
                });
    
                const data = response.data;
                console.log(data);
    
                // Assuming 'products' is defined somewhere and available
                console.log(products);
    
                if (data) {
                    setMessage('Success: Login successfully!');
                    // Navigate with both user data and products
                    navigate("/customer-profile", { state: { user: data, products } });
                } else {
                    setMessage('No account found. Please create a new account.');
                    setIsLogin(false);
                }
            } catch (error) {
                setMessage(error.response ? `Error: ${error.response.data.message}` : 'Error: No response from server');
            }
        } else {
            setMessage('Error: Please fill out all fields');
        }
    };
    

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const { email, password } = formData;

    //     if (email && password) {
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_API_URL}/accounts`, {
    //                 params: { email, password },
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    //                 },
    //             });

    //             const data = response.data;
    //             console.log(data);
    //             console.log(products);

    //             if (data) {
    //                 setMessage('Success: Login successfully!');
    //                 navigate("/customer-profile", { state: { user: data } }); // Navigate with user data
    //             } else {
    //                 setMessage('No account found. Please create a new account.');
    //                 setIsLogin(false);
    //             }
    //         } catch (error) {
    //             setMessage(error.response ? `Error: ${error.response.data.message}` : 'Error: No response from server');
    //         }
    //     } else {
    //         setMessage('Error: Please fill out all fields');
    //     }
    // };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const { email, password } = formData;
    
    //     if (email && password) {
    //         try {
    //             // Step 1: Authenticate user
    //             const response = await axios.get(`${process.env.REACT_APP_API_URL}/accounts`, {
    //                 params: { email, password },
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    //                 },
    //             });
    
    //             // const user = response.data;
    //             const user = Array.isArray(response.data) ? response.data[0] : response.data;
    //             const userId = user?.uid; // Extract id correctly
    //             console.log(user);
    //             // console.log(userId);
    
    //             if (userId) {
    //                 setMessage('Success: Login successfully!');
                    
    //                 // Step 2: Update user's table with product data
    //                 const updatedUser = {
    //                     ...user,
    //                     products: [...(user.products || []), ...products], // Merge existing and new products
    //                 };
    
    //                 // await axios.put(`${process.env.REACT_APP_API_URL}/accounts/${user.id}`, updatedUser, {
    //                     await axios.put(`${process.env.REACT_APP_API_URL}/accounts/${userId}`, updatedUser, {
    //                     // console.log("User Data:", response.data);
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                         'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    //                     },
    //                 });
    
    //                 // Step 3: Navigate to customer profile
    //                 navigate("/customer-profile", { state: { user: updatedUser } });
    //             } else {
    //                 setMessage('No account found. Please create a new account.');
    //                 setIsLogin(false);
    //             }
    //         } catch (error) {
    //             setMessage(error.response ? `Error: ${error.response.data.message}` : 'Error: No response from server');
    //         }
    //     } else {
    //         setMessage('Error: Please fill out all fields');
    //     }
    // };
    

    return (
        <div className="bg-white rounded-2xl shadow-2xl flex flex-col w-full md:w-[40%] items-center max-w-4xl transition duration-1000 ease-out">
             <h2 className="p-3 text-3xl font-bold text-darkgreen">Constantine Foundation</h2>
             <div className="inline-block border-[1px] justify-center w-20 border-darkgreen border-solid"></div>
             <h3 className="text-xl font-semibold text-darkgreen pt-2">Sign In!</h3>
             <div className="flex space-x-2 m-4 items-center justify-center">
                 <div className="socialIcon"></div>
             </div>
             <form className="flex flex-col items-center justify-center w-[80%]" onSubmit={handleSubmit}>
                 <input
                    type="email"
                    name="email"
                    className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-darkgreen focus:outline-none focus:ring-0 text-darkgreen"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <input
                    type="password"
                    name="password"
                    className="rounded-2xl px-2 py-1 w-4/5 md:w-full border-[1px] border-darkgreen m-1 focus:shadow-md focus:border-pink-400 focus:outline-none focus:ring-0 text-darkgreen"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                />
                <button
                    type="submit"
                    className="rounded-2xl m-2 text-white bg-darkgreen w-2/5 px-4 py-2 shadow-md hover:text-darkgreen hover:bg-white transition duration-200 ease-in"
                >
                    Sign In
                </button>
            </form>
            {/* Existing form elements */}
            {message && <p className={`mt-4 text-sm font-medium ${message.startsWith('Error') ? 'text-red-500' : 'text-green-500'}`}>{message}</p>}
        </div>
    );
};

Login.propTypes = {
    setIsLogin: PropTypes.func.isRequired,
};

export default Login;