export default function GuaranteeFeature({ title, description }) {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col text-3xl text-black max-md:mt-10">
        <div className="self-start font-bold font-sans text-darkgreen">
          {title}
        </div>
        <div className="mt-6 mr-8 text-darkgreen font-sans max-md:mr-2.5">
          {description}
        </div>
      </div>
    </div>
  );
}