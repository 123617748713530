export const guaranteeFeatures = [
  {
    title: "Your donation is protected",
    description: "Every time you donate to someone on Constantine Foundation, you're protected.",
  },
  {
    title: "Yearlong protection",
    description: "Donations of any amount are covered for one full year after you donate.",
  },
  {
    title: "Worldwide safety",
    description: "Your donation is protected, wherever you donate from.",
  }
];