import React from "react";

export default function WhoWeAre({ closeModal }) {
    return (
        <div className="relative flex justify-center align-center items-center">
            <button
            onClick={closeModal}
            className="absolute top-0 right-1 bg-greenlight hover:bg-darkgreen text-white font-black py-1 px-3 font-sans md:pl-3 rounded-full focus:outline-none"
        >
            X
        </button>
        <div class="  w-[90%] lg:w-[80%] max-h-[80%] p-1 font-roboto overflow-y-auto bg-white rounded-xl">
        
            <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                THE FOUNDING OF CONSTANTINE AFRICAN FOUNDATION
            </div>
            <p class="mb-3 text-justify ">
                The founding of the CONSTANTINE AFRICAN FOUNDATION arose from a profound understanding of the challenges confronting 
                African communities. Driven by the belief in the transformative potential of education, Mr. George Constantine established 
                the foundation to empower individuals and communities. Through initiatives in Algaculture, handicrafts, education, healthcare,
                 and economic empowerment, the foundation supports underserved areas, offers scholarships, and implements vocational training 
                 programs. By fostering sustainable economic opportunities and uplifting communities, the foundation contributes to regional 
                 development, striving for a more prosperous Africa. Mr. Constantine's unwavering dedication and inspirational leadership 
                 drive the foundation's lasting impact and pursuit of accessible education, healthcare, and economic opportunities.
            </p>
            <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                Why We Started
            </div>
            <p class="mb-3 text-justify">
                We embarked on this journey driven by a deep desire to empower African communities. 
                Recognizing the immense potential within individuals and the need for accessible education, healthcare, and economic 
                opportunities, we established our foundation. With unwavering commitment, we strive to create lasting positive change and 
                a brighter future for Africa
            </p>
            <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                What we are focusing: 
            </div>
            <p class="mb-3 text-justify">
                In this website, we focus on highlighting the initiatives and impact of the CONSTANTINE AFRICAN FOUNDATION. 
                Our primary focus areas include Algaculture, handicrafts, education, healthcare, and economic empowerment. 
                Through engaging content, we aim to raise awareness about the foundation's work, showcase success stories, and provide 
                valuable resources for individuals interested in supporting our mission. We strive to foster a deeper understanding of 
                the importance of education, accessible healthcare, and sustainable economic development in transforming lives and 
                communities across Africa.
            </p>
            <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                How We Are Run:
            </div>
            <p class="mb-3 text-justify">
                At the Constantine African Foundation, we take pride in our unique organizational structure. We are run by a dedicated 
                team of professionals who have firsthand experience working in the field. Many of our staff members are current or former 
                field staff, bringing their expertise and deep understanding of the African communities we serve.
                </p>
                <p class="mb-3 text-justify">
                    Our association, composed primarily of these committed individuals, guides the operations of the foundation. 
                    This ensures that our decisions are informed by the real-life challenges and needs faced by the communities we aim to 
                    support. By being run by those who have witnessed the realities on the ground, we can effectively tailor our initiatives 
                    and programs to make a meaningful and lasting impact.</p><p class="mb-3 text-justify">This approach allows us to maintain a strong 
                    connection with our African communities and ensures that our efforts are aligned with their specific requirements. 
                    We are committed to upholding the principles of transparency, accountability, and community-driven development in all 
                    aspects of our work. Together, as a team driven by our collective experiences, we are dedicated to driving positive 
                    change and transforming lives throughout the continent.
                    </p>
                    <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                        The Constantine African Association
                    </div>
                    <p class="mb-3 text-justify">
                        The Constantine African Association, the driving force behind the Constantine African Africa." 
                        This vibrant nation serves as a hub for our operations and allows us to be Foundation, is proud to be based in 
                        Ghana,Ivory Coast, Zambia and Uganda, often referred to as the "Flame of closely connected to the African communities we serve.
                    </p>
                    <p class="mb-3 text-justify">
                        Being based in Ghana,Ivory Coast, Zambia and Uganda provides us with a unique advantage, as we are immersed in understand the challenges, 
                        aspirations, and needs of the communities we work with, the rich cultural diversity and intricate dynamics of the 
                        region. It enables us to better ensuring our initiatives are relevant and impactful.
                    </p>
                    <p class="mb-3 text-justify">
                    Ghana,Ivory Coast, Zambia and Uganda's spirit of resilience and its people's unwavering determination serve as a constant source of inspiration 
                        for our team. We draw upon the country's vibrant energy and passion to drive positive change throughout the continent.
                    </p>
                    <p class="mb-3 text-justify"> 
                        Through our presence in Ghana,Ivory Coast, Zambia and Uganda, we forge strong partnerships with local organizations, government bodies, and 
                        community leaders. These collaborations are essential in co-creating sustainable solutions and ensuring that our 
                        efforts are community-led and context-specific.
                    </p>
                    <p class="mb-3 text-justify">
                        As a Ghana,Ivory Coast, Zambia and Ugandan-based organization, we are committed 
                        to uplifting African communities and igniting the flame of hope and transformation across the continent. With our 
                        roots firmly planted in Ghana,Ivory Coast, Zambia and Uganda's soil, we are dedicated to making a lasting impact, empowering individuals, and 
                        creating a brighter future for all of Africa
                    </p>
                    <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                        Our resources, The Practical Guide to Humanitarian Law. 
                    </div>
                    <p class="mb-3 text-justify">
                        As the Constantine African Foundation, we are committed to providing valuable resources to support our mission of 
                        driving positive change and transforming lives. One of the critical resources we offer is "The Practical Guide to 
                        Humanitarian Law."
                    </p>
                    <p class="mb-3 text-justify">
                        "The Practical Guide to Humanitarian Law" serves as a comprehensive and 
                        essential reference for understanding the principles and practices of humanitarian law. This guide is designed to 
                        equip individuals and organizations with the knowledge and tools to navigate the complex landscape of humanitarian 
                        work.
                    </p>
                    <p class="mb-3 text-justify">Within the guide, readers will find in-depth information on various aspects of humanitarian 
                        law, including the legal framework, principles of neutrality and impartiality, protection of civilians, and 
                        guidelines for responding to crises. It alsoexplores topics such as human rights, international humanitarian 
                        standards, and best practices in the field.
                    </p>
                    <p class="mb-3 text-justify">
                        We aim to empower individuals working in humanitarian 
                        and development sectors, as well as those interested in supporting our cause, with the necessary knowledge to make 
                        informed decisions and contribute effectively to the betterment of African communities.
                    </p>
                    <p class="mb-3 text-justify">
                        "The Practical Guide to Humanitarian Law" reflects our commitment to excellence and our belief in the power of 
                        education and knowledge sharing. By providing this resource, we hope to foster a deeper understanding of 
                        humanitarian principles and promote adherence to international standards to pursue sustainable development and 
                        positive change.</p><p class="mb-3 text-justify">We encourage individuals and organizations to make use of this valuable 
                        resource as they strive to make a difference in the lives of those most in need. Together, we can build a brighter 
                        future for Africa, guided by the principles of humanitarian law and a shared commitment to the well-being and 
                        dignity of all.
                    </p>
                    <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                        International Activity and Financial Reports; 
                    </div>
                    <p class="mb-3 text-justify">
                        As an organization committed to transparency and accountability, the Constantine African Foundation provides 
                        comprehensive International Activity and Financial Reports. These reports offer a detailed overview of our 
                        activities and financial management, ensuring that our supporters, partners and the public have access to reliable 
                        and up-to-date information.
                    </p>
                    <p class="mb-3 text-justify">
                        Our International Activity Reports highlight the impact of our 
                        initiatives across Africa. They provide a comprehensive overview of our projects, partnerships, and the communities 
                        we serve. Through engaging narratives, success stories, and compelling data, these reports showcase the positive 
                        changes we are driving and the lives we are transforming.
                    </p>
                    <p class="mb-3 text-justify">
                        In addition, our Financial Reports offer a transparent view of our financial operations. They provide a breakdown 
                        of our income and expenditure, detailing how funds are allocated and managed. These reports adhere to international 
                        accounting standards, ensuring accuracy and reliability in our financial reporting.
                    </p>
                    <p class="mb-3 text-justify">By making our International Activity and Financial Reports readily available, we aim to build trust 
                        and maintain open lines of communication with our stakeholders. We believe in the importance of accountability and 
                        ensuring that our supporters can see the direct impact of their contributions.
                    </p>
                    <p class="mb-3 text-justify">
                        At the Constantine African Foundation, we are committed to responsible stewardship of resources and maintaining 
                        the highest standards of financial integrity. Our International Activity and Financial Reports reflect this 
                        commitment, providing a comprehensive and transparent snapshot of our work and financial practices.
                    </p>
                    <p class="mb-3 text-justify">
                        We invite our supporters, partners, and the public to explore these reports, as they are a testament to our 
                        dedication to driving positive change, transforming lives, and creating a brighter future for Africa. Together, 
                        we can continue to make a lasting impact on the communities we serve
                    </p>
                    <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold">
                        Our behavioral commitments
                    </div>
                    <p class="mb-3 text-justify">
                        At the Constantine African Foundation, we place great importance on maintaining high ethical standards and 
                        behavioral commitments within our organization. We firmly believe that the integrity of our work is upheld by the 
                        conduct and behavior of each individual staff member, regardless of their location or role.
                    </p>
                    <p class="mb-3 text-justify">
                        Our behavioral commitments revolve around fostering a culture of respect, empathy, and professionalism. 
                        We expect all staff members to treat the communities we serve with the utmost respect, acknowledging their dignity, 
                        values, and unique perspectives. We promote an inclusive and non-discriminatory environment, where diversity is 
                        celebrated and all individuals are treated fairly and equitably.
                    </p>
                    <p class="mb-3 text-justify">
                        Transparency and accountability are key pillars of our behavioral commitments. We strive to be open in our 
                        communication and decision-making processes, ensuring that our stakeholders are informed and engaged. We take 
                        responsibility for our actions, learn from our mistakes, and continuously improve our practices.
                    </p>
                    <p class="mb-3 text-justify">
                        In addition, we emphasize the importance of integrity and ethical behavior in all aspects of our work. 
                        We require our staff members to act in the best interests of the communities we serve, maintaining confidentiality, 
                        and upholding professional standards. This includes managing conflicts of interest, avoiding any form of corruption, 
                        and adhering to local laws and regulations.
                    </p>
                    <p class="mb-3 text-justify">
                        By adhering to these behavioral commitments, we ensure that our organization operates with the highest level of 
                        integrity and earns the trust of the communities we work with. We regularly provide training and support to our 
                        staff members to reinforce these commitments and promote a positive organizational culture.
                    </p>
                    <p class="mb-3 text-justify">
                        At the Constantine African Foundation, we are dedicated to upholding these behavioral commitments as we strive to 
                        make a positive impact on the lives of individuals and communities in Africa. We believe that through our collective 
                        efforts, guided by these commitments, we can create a more equitable and sustainable future for all.
                    </p>
                    <p class="mb-3 text-justify">      
                    </p>
                    <div class="text-[1.2rem] md:text-[2.5rem] lg:text-[2.5rem] text-center text-greenshade font-bold"> 
                        Work With Us
                    </div>
                    <p class="mb-3 text-justify">
                        Are you passionate about making a difference in the lives of African communities? Do you believe in the power of 
                        education, handicrafts such as carpentry and other crafts, healthcare, and economic empowerment? If so, we invite 
                        you to join us and work together toward a brighter future for Africa.
                    </p>
                    <p class="mb-3 text-justify">
                        At the Constantine African Foundation, we value the contributions of dedicated and driven individuals who share 
                        our vision of driving positive change. We offer various opportunities for individuals to collaborate and make an 
                        impact.
                    </p>
                    <p class="mb-3 text-justify">
                        Whether you are an experienced professional, a student, or a volunteer, there are several 
                        ways you can work with us. You can contribute your skills and expertise by joining our team on the ground, helping 
                        to implement and manage our projects in Algaculture, handicrafts, education, healthcare, and economic empowerment. 
                        Alternatively, you can support us remotely by assisting with research, fundraising, communications, or advocacy 
                        efforts.
                    </p>
                    <p class="mb-3 text-justify">
                        We also welcome partnerships and collaborations with organizations that share our 
                        values and objectives. By pooling our resources and expertise, we can amplify our impact and create sustainable 
                        change in African communities.
                    </p>
                    <p class="mb-3 text-justify">
                        If you are interested in working with us, we encourage you to 
                        visit our website and explore available opportunities. You can find more information about our current projects, 
                        volunteering opportunities, and how to get involved. We believe that together, we can make a lasting impact and 
                        create a brighter future for Africa.
                    </p>
                    <p class="mb-3 text-justify">
                        Join us in our mission to empower individuals, transform 
                        lives, and contribute to regional development. Together, we can drive positive change and make a difference in the 
                        lives of African communities. enable future for all
                    </p>
                </div>
    </div>
    )
    
}