import React, { useEffect, useState } from "react";
import back from "../../assets/Donation section/SVG/home page donation .png";
import { motion } from "framer-motion";
import boxd1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxd2 from "../../assets/Headers/SVG/Asset_3.svg";
import { Link } from "react-router-dom";
import axios from "axios";

const cardVariants = {
  offscreen: {
    y: 200,
  },
  onscreen: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const Donation = () => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");

  // Fetch data from Strapi
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;
        const response = await axios.get(url); // Replace with your Strapi endpoint
        const projects = response.data.data.map((project) => ({
          id: project.id,
          name: project.attributes.name,
        }));
        setProjectOptions(projects);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className="flex flex-wrap w-full" id="donation">
      <div className="flex w-full">
        <img
          className="lg:absolute lg:h-full lg:left-[40%] md:absolute md:h-[50%] md:w-[60%] md:left-[30%] absolute h-[85%] left-[0%] lg:pt-0 md:pt-0 pt-32"
          alt="bake"
          src={back}
        />
        <div className="w-11/12 m-auto md:mb-32 mb-10 flex flex-col gap-1 items-left text-center py-10 relative">
          <div className="flex mt-10 z-10 flex-col relative text-center">
            <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]">
              {/* Motion elements here */}
            </motion.div>
            <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem] text-greenshade w-[100%] md:w-[50%] ml-[-3%] sm:l-[25%]">
              Make Donation
            </p>
            <motion.p
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
              variants={cardVariants}
              className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] mb-2 text-green md:mb-5 capitalize w-[100%] md:w-[50%] ml-[-5%]"
            >
              Be a part of what we achieved so far
            </motion.p>
          </div>
          <div className="flex w-full gap-10 relative py-[5rem]">
            <motion.div
              initial={{
                x: -400,
                opacity: 0,
              }}
              viewport={{ once: true }}
              whileInView={{
                x: 0,
                scale: 1,
                opacity: 1,
                transition: {
                  duration: 0.8,
                  bounce: 1,
                  type: "easy",
                },
              }}
              className="lg:h-[25rem] lg:w-[38rem] md:h-[25rem] md:w-[25rem] h-[32rem] w-[20rem] bg-green rounded-3xl lg:p-10 md:p-5 p-3 text-left text-white lg:pt-0 md:pt-0 pt-32"
            >
              <p className="font-roboto font-bold md:text-[1.3rem] text-[1.1rem] my-5 md:mb-10">
                PLACE YOUR DONATION TODAY
              </p>
              <input
                type="text"
                placeholder="Please enter the amount to donate here"
                className="md:w-9/12 w-full mb-6 border-2 bg-green text-white border-white rounded-xl p-3"
              />
              {/* Dropdown for purposes */}
              <select
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.target.value)}
                className="md:w-9/12 w-full mb-6 border-2 bg-green text-white border-white rounded-xl p-3"
              >
                <option value="" disabled>
                  Please select the purpose of your donation
                </option>
                {projectOptions.map((project) => (
                  <option key={project.id} value={project.name}>
                    {project.name}
                  </option>
                ))}
              </select>
              <div className="md:w-6/12 w-9/12 mb-6 text-greendark text-center flex gap-2">
                <div className="bg-white rounded-xl p-3 w-3/12">10 $</div>
                <div className="bg-white rounded-xl p-3 w-3/12">50 $</div>
                <div className="bg-white rounded-xl p-3 w-6/12">100 $</div>
              </div>
              <div className="md:w-6/12 w-9/12 mb-6 border-2 border-white text-center rounded-xl p-3">
                <Link onClick={() => window.scrollTo(0, 0)} to={"/donate"}>
                  Donate
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="w-3/4 m-auto h-1 bg-black"></div>
    </div>
  );
};

export default Donation;
