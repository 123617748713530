import React from 'react';
import upcoming from '../../../src/assets/events/UpComingEvent.png';

function FoundationInfo() {
  return (
    <div className="mt-[5%] font-roboto w-[90%] lg:w-[80%] m-auto my-20 border-2 border-lightgray shadow-xl p-10">
      <div className="flex md:flex-row flex-col-reverse items-center">
        <div className="w-full">
          <div className="text-[1rem] md:text-[1.4rem] xl:text-[1.9rem] font-bold">
            OUR WORK IS BEGINNING. COME AND GET TO KNOW US!
          </div>
          <div className="text-[0.7rem] xl:text-[0.9rem] font-light text-justify mr-5 text-gray">
          With the best hopes for the common good of Africans, the CONSTANTINE FOUNDATION begins its pioneering actions! Soon, the Foundation will inaugurate its headquarters in Kampala, Uganda, where activities will be organized, plans drawn up, and achievements presented. We are also establishing branch offices in Accra, Ghana; Abidjan, Ivory Coast; and Lusaka, Zambia, to expand our impact across the continent. Through the dedicated efforts of our team and the invaluable support of volunteers, we aim to realize our goals and foster positive change. We look forward to welcoming you to join us in this mission—your presence will be a great honor. Come see what our plans are and be part of this transformative journey!
          </div>
          <div className="flex justify-center mt-5 text-center">
            <div className="bg-orange md:text-[1rem] text-[0.7rem] p-3 text-white">
              Stay tuned for the upcoming event! Date and time details will be revealed shortly. Excitement awaits!
            </div>
          </div>
        </div>
        <div className="xl:w-[20rem] xl:h-[20rem] lg:w-[16rem] lg:h-[16rem] md:w-[14rem] md:h-[14rem] w-[10rem] h-[10rem] flex-none rounded-full overflow-hidden">
          <img 
            alt="banerimage" 
            src={upcoming}
            />
        </div>
      </div>
    </div>
  );
}

export default FoundationInfo;
