import React from "react";
import { motion } from "framer-motion";

const PrivacyPolicy = () => {
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="p-6 bg-greenshade text-white pt-20"
        >
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold text-center mb-8">Privacy Policy</h1>
                <div className="space-y-6">
                    <p>Effective Date: [Insert Date]</p>
                    <p>
                        The Constantine African Foundation ("CAF," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our website and services.
                    </p>
                    <h2 className="text-2xl font-semibold">1. Information We Collect</h2>
                    <p>
                        We collect information that you provide to us directly and automatically when you use our services. This may include:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Personal Information: Name, email address, phone number, and other contact details.</li>
                        <li>Usage Data: Information about your interactions with our website, such as IP address, browser type, pages visited, and time spent on our site.</li>
                    </ul>
                    <h2 className="text-2xl font-semibold">2. How We Use Your Information</h2>
                    <p>
                        We use the information we collect to:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Provide, operate, and maintain our services.</li>
                        <li>Improve, personalize, and expand our services.</li>
                        <li>Communicate with you, including responding to your inquiries and providing updates.</li>
                        <li>Understand and analyze how you use our services.</li>
                        <li>Develop new products, services, features, and functionality.</li>
                    </ul>
                    <h2 className="text-2xl font-semibold">3. Sharing Your Information</h2>
                    <p>
                        We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share information with trusted third parties who assist us in operating our website, conducting our business, or serving our users, provided those parties agree to keep this information confidential.
                    </p>
                    <h2 className="text-2xl font-semibold">4. Data Security</h2>
                    <p>
                        We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.
                    </p>
                    <h2 className="text-2xl font-semibold">5. Your Rights</h2>
                    <p>
                        You have the right to:
                    </p>
                    <ul className="list-disc list-inside ml-4">
                        <li>Access, update, or delete the information we have on you.</li>
                        <li>Object to or restrict the processing of your information.</li>
                        <li>Withdraw your consent at any time where CAF relied on your consent to process your personal information.</li>
                    </ul>
                    <h2 className="text-2xl font-semibold">6. Cookies</h2>
                    <p>
                        We use cookies and similar tracking technologies to track activity on our website and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our services.
                    </p>
                    <h2 className="text-2xl font-semibold">7. Changes to This Privacy Policy</h2>
                    <p>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes are effective when they are posted on this page.
                    </p>
                    <h2 className="text-2xl font-semibold">8. Contact Us</h2>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at:
                        <br />
                        Email: [contact@caf.org]
                        <br />
                        Phone: [Your Contact Number]
                    </p>
                    <p>
                        By using our services, you consent to our Privacy Policy.
                    </p>
                </div>
            </div>
        </motion.div>
    );
}

export default PrivacyPolicy;
