import React from "react";
import { motion } from "framer-motion";

const TermsOfService = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-6 bg-greenshade text-white pt-20"
    >
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-8">Terms of Service</h1>
        <div className="space-y-6">
          <p>Effective Date: [Insert Date]</p>
          <p>
            Welcome to the Constantine African Foundation ("CAF"). By accessing and using our website and services, you agree to comply with and be bound by the following terms of service. Please review these terms carefully.
          </p>
          <h2 className="text-2xl font-semibold">1. Acceptance of Terms</h2>
          <p>
            By using our website and services, you agree to these Terms of Service and any additional terms applicable to certain programs in which you may elect to participate.
          </p>
          <h2 className="text-2xl font-semibold">2. Changes to Terms</h2>
          <p>
            CAF reserves the right to modify these Terms of Service at any time. Any changes will be effective immediately upon posting the revised terms. Your continued use of the site and services signifies your acceptance of the revised terms.
          </p>
          <h2 className="text-2xl font-semibold">3. Use of Services</h2>
          <p>
            You agree to use our services only for lawful purposes and in accordance with all applicable laws and regulations. You must not use our services to:
          </p>
          <ul className="list-disc list-inside ml-4">
            <li>Engage in any fraudulent, unlawful, or harmful activity.</li>
            <li>Transmit any harmful code or data.</li>
            <li>Interfere with the operation of our services.</li>
          </ul>
          <h2 className="text-2xl font-semibold">4. Privacy</h2>
          <p>
            Your use of our services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. Please review our Privacy Policy for more details.
          </p>
          <h2 className="text-2xl font-semibold">5. Intellectual Property</h2>
          <p>
            All content, trademarks, and data on this website, including but not limited to text, graphics, logos, images, and software, are the property of CAF or its content suppliers and are protected by international intellectual property laws.
          </p>
          <h2 className="text-2xl font-semibold">6. Limitation of Liability</h2>
          <p>
            CAF will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of, or inability to use, our services, even if we have been advised of the possibility of such damages.
          </p>
          <h2 className="text-2xl font-semibold">7. Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless CAF and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable legal fees and costs, arising out of or in any way connected with your access to or use of our services.
          </p>
          <h2 className="text-2xl font-semibold">8. Termination</h2>
          <p>
            CAF reserves the right to terminate or suspend your access to our services, without notice, for conduct that we believe violates these Terms of Service or is harmful to other users of our services, us, or third parties, or for any other reason at our sole discretion.
          </p>
          <h2 className="text-2xl font-semibold">9. Governing Law</h2>
          <p>
            These Terms of Service shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
          </p>
          <h2 className="text-2xl font-semibold">10. Contact Us</h2>
          <p>
            If you have any questions about these Terms of Service, please contact us at:
            <br />
            Email: [contact@caf.org]
            <br />
            Phone: [Your Contact Number]
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default TermsOfService;
