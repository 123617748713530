import React from "react";
import { Card, CardContent, CardMedia, CardActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const ContentCard = ({Data}) => {

  console.log(Data,'Data')
  const navigate = useNavigate();
  // const handleButtonClick = () => {
  //   navigate(`/blogs/${x.id}`);
  //   window.scrollTo(0, 0);
  // };

  return (
<div className="gap-4 flex flex-row flex-wrap pl-16">

{
  Data?.map((x) => (
    <div key={x.id}>
      <motion.div
        initial={{
          y: 250,
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
          scale: 1,
          transition: {
            duration: 0.8,
            delay: 0,
          },
        }}
        viewport={{ once: true }}
        className="w-[320px] md:w-[300px] lg:w-[350px] max-w-[500px]"
      >

        <Card>
          <CardMedia
            component="img"
            alt={x.alt}
            className="h-[250px]"
            image={process.env.REACT_APP_API_IMAGE_URL + x.attributes?.imageUrl?.data[0].attributes?.url}
          />
          <CardContent className="h-[150px] md:h-[200px]">
            <h3 className="text-greenshade text-2xl">{x.attributes?.title}</h3>
            <p className="py-[2rem] pl-[1.5rem] font-sans text-justify font-semibold">
              {x.attributes?.description?.slice(0, 100)}
              <span className="inline text-[#A9A9A9]">...</span>
            </p>
          </CardContent>
          <CardActions className="flex justify-end p-28 lg:mt-12 md:mt-12 mt-40">
            <button
              className="bg-[#f29511] font-sans font-semibold text-white p-[2%] w-[45%] md:w-[50%] rounded-md mt-12"
              onClick={() => {
                navigate(`/blogs/${x.id}`);
                window.scrollTo(0, 0);
              }}
            >
              Read The Blog
            </button>
          </CardActions>
        </Card>
      </motion.div>
    </div>
  ))
}

</div>


  );
};

export default ContentCard;
