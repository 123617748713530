// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import axios from "axios";
// import emailjs from "emailjs-com";
// import { FaMapLocationDot } from "react-icons/fa6";
// import { BsFillCalendarDateFill } from "react-icons/bs";

// const UpEventDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [Data, setData] = useState(null);
//   const [full_name, setFull_name] = useState("");
//   const [event_name, setEvent_name] = useState("");
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");

//   const url = process.env.REACT_APP_API_URL + `/events/${id}?populate=*`;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data } = await axios({
//           method: "get",
//           url: url,
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
//           },
//         });

//         setData(data?.data);
//       } catch (error) {
//         console.log("Error fetching event data:", error.message);
//       }
//     };

//     fetchData();
//   }, [url]);

//   // Helper function to format the timestamp
//   const formatDate = (timestamp) => {
//     const date = new Date(timestamp);
//     return date.toLocaleDateString("en-US", {
//       weekday: "long",
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//     });
//   };

//   // Function to handle email subscription
//   const handleSubscribe = (e) => {
//     e.preventDefault();
//     if (!email) {
//       setMessage("Please enter a valid email address.");
//       return;
//     }

//     emailjs
//   .send(
//     process.env.REACT_APP_EMAILJS_SERVICE_ID,
//     process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
//     {
//       full_name,
//       event_name,
//       user_email: email,
//     },
//     process.env.REACT_APP_EMAILJS_PUBLIC_KEY
//   )
//   .then(
//     (result) => {
//       setMessage("Subscription successful! Thank you.");
//       setFull_name("");
//       setEvent_name("");
//       setEmail("");
//     },
//     (error) => {
//       console.error("Failed to send subscription email:", error.text);
//       setMessage("Subscription failed. Please try again later.");
//     }
//   );

//   };

//   return (
//     <>
//       <div className="flex flex-col p-28 pb-5 bg-darkgren">
//         <div className="items-center px-16 pt-6 pb-20 w-full lg:text-5xl md:text-5xl text-3xl text-center text-white">
//           {Data?.attributes?.name}
//         </div>
//         <div className="flex flex-col gap-5 justify-between mt-2.5 w-full text-2xl text-center text-white">
//           <div className="flex flex-row bg-lime text-start px-16 py-2">
//             <BsFillCalendarDateFill /> &nbsp; Start From {formatDate(Data?.attributes?.start_date)} - {formatDate(Data?.attributes?.end_date)}
//           </div>
//           <div className="flex flex-row text-start px-16 py-2">
//             <FaMapLocationDot /> &nbsp; at {Data?.attributes?.location}
//           </div>
//         </div>

//         <div className="mt-3.5 w-full max-md:max-w-full">
//           <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
//             <div className="flex flex-col w-[35%] max-md:ml-0 max-md:w-full">
//               <img
//                   loading="lazy"
//                   src={`${process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.imageURL?.data[0].attributes?.url}`}
//                   className="self-stretch m-auto max-w-full h-[350px] w-[500px] max-md:mt-10"
//               />
//             </div>
//             <div className="flex flex-col ml-5 w-[65%] max-md:ml-0 max-md:w-full pb-10 mt-[-7rem]">
//               <div className="font-sans pt-48 pr-4 pb-12 pl-0 w-full text-justify lg:text-2xl md:text-xl text-xl text-white  max-md:pt-10 max-md:pl-5 max-md:max-w-full">
//               {Data?.attributes?.description}
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="mt-10 p-5 bg-gray-700 text-white rounded-lg">
//           <h2 className="text-2xl font-bold mb-4">Subscribe for Event Updates</h2>
//           <form onSubmit={handleSubscribe} className="flex flex-col gap-4">
//             <input
//               type="text"
//               placeholder="Enter your full name"
//               value={full_name}
//               onChange={(e) => setFull_name(e.target.value)}
//               className="p-3 rounded-lg text-black"
//               required
//             />
//             <input
//               type="text"
//               placeholder="Enter event name"
//               value={event_name}
//               onChange={(e) => setEvent_name(e.target.value)}
//               className="p-3 rounded-lg text-black"
//               required
//             />
//             <input
//               type="email"
//               placeholder="Enter your email address"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               className="p-3 rounded-lg text-black"
//               required
//             />
//             <button
//               type="submit"
//               className="p-3 bg-newgreen rounded-lg text-white hover:bg-green"
//             >
//               Subscribe
//             </button>
//           </form>
//           {message && <p className="mt-3 text-yellow-600">{message}</p>}
//         </div>
//       </div>
//     </>
//   );
// };

// export default UpEventDetail;

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import emailjs from "emailjs-com";
import { FaMapLocationDot } from "react-icons/fa6";
import { BsFillCalendarDateFill } from "react-icons/bs";

const UpEventDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState(null);
  const [full_name, setFull_name] = useState("");
  const [event_name, setEvent_name] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const url = process.env.REACT_APP_API_URL + `/events/${id}?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        setData(data?.data);
      } catch (error) {
        console.log("Error fetching event data:", error.message);
      }
    };

    fetchData();
  }, [url]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleSubscribe = (e) => {
    e.preventDefault();
    if (!email) {
      setMessage("Please enter a valid email address.");
      return;
    }

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        {
          full_name,
          event_name,
          user_email: email,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setMessage("Subscription successful! Thank you.");
          setFull_name("");
          setEvent_name("");
          setEmail("");
        },
        (error) => {
          console.error("Failed to send subscription email:", error.text);
          setMessage("Subscription failed. Please try again later.");
        }
      );
  };

  return (
    <div className="p-6 md:p-10 lg:p-28 pt-20 md:pt-20 lg:pt-20 bg-darkgren">
      {/* Event Title */}
      <h1 className="text-3xl md:text-4xl lg:text-5xl text-white text-center mb-10">
        {Data?.attributes?.name}
      </h1>

      {/* Event Details */}
      <div className="flex flex-col md:flex-row justify-between items-start gap-6">
        {/* Event Information */}
        <div className="flex flex-col text-white gap-4">
          <div className="flex items-center gap-2 bg-lime p-4 rounded-lg text-start">
            <BsFillCalendarDateFill />
            <span>
              Start From {formatDate(Data?.attributes?.start_date)} -{" "}
              {formatDate(Data?.attributes?.end_date)}
            </span>
          </div>
          <div className="flex items-center gap-2 bg-lime p-4 rounded-lg text-start">
            <FaMapLocationDot />
            <span>at {Data?.attributes?.location}</span>
          </div>
        </div>

        {/* Event Image */}
        <div className="w-full md:w-1/2">
          <img
            loading="lazy"
            src={`${process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.imageURL?.data[0].attributes?.url}`}
            className="object-contain rounded-lg max-h-[350px] w-full"
            alt="Event"
          />
        </div>
      </div>

      {/* Event Description */}
      <div className="mt-8 text-white font-sans text-lg md:text-xl lg:text-2xl leading-relaxed text-justify">
        {Data?.attributes?.description}
      </div>

      {/* Subscription Form */}
      <div className="mt-10 p-6 bg-gray-700 rounded-lg">
        <h2 className="text-2xl font-bold text-white mb-4">Subscribe for Event Updates</h2>
        <form onSubmit={handleSubscribe} className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Enter your full name"
            value={full_name}
            onChange={(e) => setFull_name(e.target.value)}
            className="p-3 rounded-lg text-black"
            required
          />
          <input
            type="text"
            placeholder="Enter event name"
            value={event_name}
            onChange={(e) => setEvent_name(e.target.value)}
            className="p-3 rounded-lg text-black"
            required
          />
          <input
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-3 rounded-lg text-black"
            required
          />
          <button
            type="submit"
            className="p-3 bg-newgreen rounded-lg text-white hover:bg-green"
          >
            Subscribe
          </button>
        </form>
        {message && <p className="mt-3 text-yellow-600">{message}</p>}
      </div>
    </div>
  );
};

export default UpEventDetail;
