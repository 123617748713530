// import { Link } from "react-router-dom";
// import axios from "axios";
// import { useContext } from "react";
// import { Store } from "../Store";
// import { url } from "../../data";

// function Product(props) {
//   const { product } = props;
// console.log(product,'product')
//   const { state, dispatch: ctxDispatch } = useContext(Store);
//   const {
//     cart: { cartItems },
//   } = state;

//   const addToCartHandler = async (item, product) => {
//     const existItem = cartItems.find((x) => x.id === product?.data?.id);
//     const quantity = existItem ? existItem.quantity + 1 : 1;

//     if (product?.data[0].attributes?.countInStock < quantity) {
//       window.alert("Sorry. Product is out of stock");
//       return;
//     }
//     ctxDispatch({
//       type: "CART_ADD_ITEM",
//       payload: { ...item, quantity },
//     });
//   };

//   return (
//     <div style={{ marginTop: "30px", marginBottom: "30px" }}>
//       <div
//         className={`bg-white rounded-lg flex  items-left flex-col overflow-hidden`}
//       >
//         <Link to={`/product/${product.id}`}>
//           <img
//            src={process.env.REACT_APP_API_IMAGE_URL + product?.attributes?.imageURL?.data[0].attributes?.url}
//             className={` w-full md:h-[15rem] sm:h-[16rem] h-[17rem] `}
//             alt={product?.attributes?.name}
//           />
//         </Link>
//         <div className="px-4 pb-4 text-left">
//           <Link
//             to={`/product/${product?.id}`}
//             // style={{ textDecoration: "none" }}
//           >
//             <h2 className={`font-roboto font-bold text-[1.5rem] text-green `}>
//               {product?.attributes?.name}
//             </h2>
//           </Link>
//           <p className={`mb-4 `}>{product?.attributes?.description}</p>
//           <p className={`font-roboto font-bold text-[0.8rem] text-green `}>
//             {product?.attributes?.price} USD | {product?.attributes?.gbp} GBP
//           </p>
//           {product.countInStock === 0 ? (
//             <button className={`bg-white text-gray p-2 shadow-md `} disabled>
//               Out of stock
//             </button>
//           ) : (
//             <button
//               onClick={() => addToCartHandler(product)}
//               className={` text-white bg-blue-100-600 p-2 rounded-lg  text-base`}
//             >
//               Add to cart
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Product;






import { Link } from "react-router-dom";
import axios from "axios";
import { useContext } from "react";
import { Store } from "../Store";
import { slugify } from "../../utils/slugify";

function Product(props) {
  const { product } = props;
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    cart: { cartItems },
  } = state;

  const addToCartHandler = async (item) => {
    const existItem = cartItems.find((x) => x.id === item.id);
    const quantity = existItem ? existItem.quantity + 1 : 1;

    if (item.attributes.countInStock < quantity) {
      window.alert("Sorry. Product is out of stock");
      return;
    }
    ctxDispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
  };

  return (
    <div style={{ marginTop: "30px", marginBottom: "30px" }}>
      <div className="bg-white rounded-lg flex items-left flex-col overflow-hidden">
        {/* <Link to={`/product/${slugify(product.attributes.name)}`}> */}
          <img
            src={
              process.env.REACT_APP_API_IMAGE_URL +
              product.attributes.imageURL.data[0].attributes.url
            }
            className="w-full md:h-[15rem] sm:h-[16rem] h-[17rem]"
            alt={product.attributes.name}
          />
        {/* </Link> */}
        <div className="px-4 pb-4 text-left">
          <Link to={`/product/${slugify(product.attributes.name)}`}>
            <h2 className="font-roboto font-bold text-[1.5rem] text-green">
              {product.attributes.name}
            </h2>
          </Link>
          <p className="mb-4 line-clamp-2">{product.attributes.description}</p>
          <p className="font-roboto font-bold text-[0.8rem] text-green">
            {product.attributes.price} USD | {product.attributes.gbp} GBP
          </p>
          {product.attributes.countInStock === 0 ? (
            <button className="bg-white text-gray p-2 shadow-md" disabled>
              Out of stock
            </button>
          ) : (
            <button
              onClick={() => addToCartHandler(product)}
              className="text-white bg-green p-2 rounded-lg text-base"
            >
              Add to cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Product;
