import React from "react";

function DownloadCard({ imageUrl, title, buttonText, reportId }) {
  const handleDownload = async () => {
    if (!reportId) {
      console.error("Report ID is missing. Unable to download the file.");
      return;
    }

    try {
      // Fetch the report details
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/subscriptions/${reportId}?populate=report_file`
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch the report. Status: ${response.status}`);
      }

      const { data } = await response.json();

      console.log("API Response:", data);

      // Extract the file URL from the API response
      // const fileUrl = data?.attributes?.report_file?.data?.attributes?.url;
      // console.table(fileUrl)

      // if (!fileUrl) {
      //   console.error("File URL is missing in the API response.");
      //   return;
      // }

      // // Construct the full URL
      // const fullFileUrl = `${process.env.REACT_APP_API_URL}${fileUrl}`;

      const fullFileUrl = data?.attributes?.report_file?.data[0].attributes?.url;
      console.table(fullFileUrl);
      const name = data?.attributes?.report_file?.data[0].attributes?.hash;
      console.table(name)

      // Create a temporary anchor element to download the file
      const link = document.createElement("a");
      link.href = fullFileUrl;
      link.download = `${name}.pdf`; // Set the default file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error.message, error);
    }
  };

  return (
    <article className="flex flex-col grow items-center text-2xl text-center max-md:mt-1">
      <video
        autoPlay
        muted
        loop
        className="object-contain self-stretch w-[50%] h-[100%] mx-auto rounded-full"
      >
        <source src={imageUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <h2 className="mt-6 text-white">{title}</h2>
      <button
        onClick={handleDownload}
        className="px-5 pt-2.5 pb-2.5 mt-6 max-w-full text-white whitespace-nowrap bg-green rounded-[50px] w-[150px] max-md:px-5"
        aria-label={`Download ${title}`}
      >
        {buttonText}
      </button>
    </article>
  );
}

export default DownloadCard;
