import React from "react";
import asset1 from "../../assets/COnstantine Be Ambassador Headere.png";
import BecomeAMember from "./BecomeAMember";

const BecomeAmbasador = () => {
  return (
    <div>
      {/* Header Section */}
      <div
        className="relative text-justify h-[17rem] md:h-[34rem] lg:h-[55rem] w-full overflow-hidden flex items-end"
        id="header"
      >
        <img
          src={asset1}
          alt="header"
          className="absolute inset-0 -z-10 w-full object-cover object-center"
        />
      </div>

      {/* Main Content Section */}
      <div className="px-4 md:px-6 lg:px-20 text-justify pt-0 md:pt-1 lg:pt-16 text-darkgreen">
        <h1 className="text-3xl md:text-4xl lg:text-5xl text-center">
          Become an Ambassador
        </h1>
        <div className="py-8 md:py-10 lg:py-12 space-y-6">
          <p className=" font-sans">
            Join the Constantine African Foundation as an Ambassador and amplify the power of your connections!
            Embrace the opportunity to become a catalyst for positive change.
          </p>
          <p className=" font-sans">
            Our Ambassadors are not just volunteers; they are dynamic individuals infused with passion,
            dedicating their skills and time to create a lasting impact.
          </p>
          <p className=" font-sans">
            By joining us, you'll be at the forefront of spreading awareness about our impactful initiatives.
            As an Ambassador, you'll play a pivotal role in fostering meaningful partnerships with businesses and
            corporate sponsors, showcasing the true value of your network.
          </p>
          <p className=" font-sans">
            Step into a realm where your influence transforms lives, where your connections contribute to a brighter future.
          </p>
        </div>

        {/* Call to Action */}
        <strong className="block text-xl md:text-2xl lg:text-3xl text-center px-2 py-8">
          Be a part of something extraordinary—Become a Constantine African Foundation Ambassador today!
        </strong>

        {/* Responsibilities Section */}
        <h1 className="text-xl md:text-2xl lg:text-3xl px-2 md:px-6 lg:px-20 text-center py-8">
          What are my responsibilities as an ambassador?
        </h1>
        <p className="font-sans px-4 md:px-6 lg:px-20 py-3">
          As a Constantine African Foundation Ambassador, your role is nothing short of transformative! Dive into impactful
          responsibilities that not only leverage your skills but also demonstrate the immense value of your connections:
        </p>
        <ol className="px-6 md:px-12 lg:px-40 space-y-4">
          <li className="font-sans">
            <b>1. Community Catalyst:</b> Immerse yourself in the local community as a research agent, delving into data collection to identify needs and
            formulate innovative ideas for projects aimed at reducing unemployment.
          </li>
          <li className="font-sans">
            <b>2. Fundraising Maestro:</b> Unleash your creativity in spotting and cultivating potential fundraising opportunities both locally and
            internationally, ensuring a continuous influx of support for our vital initiatives.
          </li>
          <li className="font-sans">
            <b>3. Strategic Connector:</b> Act as a liaison, distributing promotional materials to interested NGOs and individuals,
            facilitating the sale of our meticulously crafted products that champion our cause.
          </li>
          <li className="font-sans">
            <b>4. Event Virtuoso:</b> Take the lead in organizing fundraising events that not only captivate but also inspire others to follow suit.
            Your role extends to promoting our programs and amplifying our reach and impact.
          </li>
          <li className="font-sans">
            <b>5. Social Media Maven:</b> Spread the word far and wide! Share our mission across various social media platforms,
            contributing to the digital wave that propels our cause to new heights.
          </li>
          <li className="font-sans">
            <b>6. Educational Trailblazer:</b> Become an advocate for change by educating individuals, businesses,
            and communities about the Constantine Foundation's Goals and Sustainable Development Objectives.
            Your talks and presentations will be the catalyst for awareness and action.
          </li>
        </ol>

        {/* Closing Statements */}
        <p className="font-sans px-4 md:px-6 lg:px-20 py-10">
          Join Constantine African Foundation as an Ambassador and elevate your influence to create a profound global impact.
          Embrace a transformative journey where your connections become catalysts for positive change. Your role transcends tasks;
          it's a dynamic showcase of your network's value.
        </p>
        <strong className="block text-xl md:text-2xl lg:text-3xl text-center px-4 py-8">
          Join us in creating positive change! Empower change with us!
        </strong>

        {/* Why Join Section */}
        <h1 className="text-2xl md:text-3xl lg:text-4xl text-center pt-10">
          Why Join Our Team?
        </h1>
        <div className="px-4 md:px-6 lg:px-20 py-5 space-y-4">
          <p className=" font-sans">
            Join our dynamic team at Constantine African Foundation and be a driving force for positive change!
          </p>
          <p className=" font-sans">
            As an Ambassador, you'll play a pivotal role in creating impactful job opportunities through our visionary projects.
          </p>
          <p className=" font-sans">
            Become a passionate advocate for the excellence of our donation-funded initiatives.
          </p>
          <p className=" font-sans">
            If you're committed to realizing dreams and championing meaningful employment, this is your platform to make a difference.
          </p>
        </div>
        <h1 className="text-xl md:text-2xl lg:text-3xl text-center">
          Join us on this exciting journey towards a brighter future!
        </h1>
      </div>

      {/* Form Section */}
      <h2 className="text-center text-lg md:text-xl lg:text-2xl px-4 py-10">
        Please complete the form below to apply to become a Constantine African Foundation Ambassador.
      </h2>
      <div className="px-4 md:px-10 lg:px-20">
        <BecomeAMember />
      </div>
    </div>
  );
};

export default BecomeAmbasador;
