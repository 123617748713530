import GuaranteeFeature from './GuaranteeFeature.jsx';
import { guaranteeFeatures } from './GuaranteeData.js';
import guarentee from '../assets/ddddd.jpg';

export default function GuaranteeSection() {
  return (
    <div className="flex overflow-hidden flex-col items-start px-32 pt-11 pb-20 bg-white max-md:px-5">
      <h1 className="ml-5 mt-28 text-8xl font-sans font-black text-center text-darkgreen max-md:max-w-full max-md:text-4xl">
        Constantine
      </h1>
      <h2 className="mt-2 ml-4 font-sans text-9xl font-black text-center text-green max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        Giving Guarantee
      </h2>
      <p className="self-center mt-12 text-4xl font-black font-sans opacity-40 text-center text-black max-md:mt-10 max-md:max-w-full">
        We guarantee you a full refund in the rare case something isn't right
      </p>
      <div className="self-stretch mt-20 max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {guaranteeFeatures.map((feature, index) => (
            <GuaranteeFeature
              key={index}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
      <img
        loading="lazy"
        src={guarentee}
        alt="Constantine Giving Guarantee illustration"
        className="object-contain mt-24 w-full aspect-[1.88] max-w-[1286px] rounded-[30px] max-md:mt-10 max-md:max-w-full"
      />
      <div className='text-center mx-auto mt-10'>
        <h1>
        We guarantee the utmost security and confidentiality of all donation data, employing advanced cybersecurity measures to ensure your trust and safety.
        </h1>
      </div>
    </div>
  );
}