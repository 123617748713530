import * as React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import asset1 from "../../src/assets/careers/ByzantineOrthodoxChantingworkshop.png";
import asset2 from "../../src/assets/careers/OrthodoxByzantineMarbleCarving.png";
import asset3 from "../../src/assets/careers/Learningbyzantineorthodoxart.png";
import Modal from "./VolunteerPopup";
import Volunteer from './volunteer';
import Career from './Career';
import header from "../../src/assets/join our team.png"

const Careers = () => {
  const [showFullText1, setShowFullText1] = useState(false);
  const [showFullText2, setShowFullText2] = useState(false);
  const [showFullText3, setShowFullText3] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null); // To track which component to show

  const headerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5, delay: 0.3 } },
  };

  const buttonVariants = {
    hover: { scale: 1.1, transition: { yoyo: Infinity } },
  };

  const cardVariants = {
    rest: { scale: 1, boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)" },
    hover: { scale: 1.05, boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)", transition: { duration: 0.3 } },
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

    const sectionVariantss = {
      hidden: { opacity: 0, y: 30 },
      visible: { opacity: 1, y: 0, transition: { duration: 0.8, staggerChildren: 0.2 } },
    };
  
    const itemVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
    };

  return (
    <>
      {/* Hero Section */}
      <motion.div
        id="header"
        className="relative w-full h-[50rem] flex items-center justify-center bg-gradient-to-r from-darkgren to-greenlight text-white"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        <img src={header} alt="gif" className="absolute h-full w-full object-cover" />
        {/* <div className="text-center z-10">
          <h1 className="text-6xl text- font-extrabold">Discover Your Journey</h1>
          <p className="text-3xl font-light mt-4">"Fuel Your Passion, Ignite Your Future with Our Foundation!"</p>
        </div> */}
      </motion.div>

      {/* Mission Section */}
      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
          Be a Catalyst for Change: Shape Africa's Future with Constantine African Foundation.
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Start up, Journey with Constantine African Foundation: Lighting the Way with Limited Resources, Amplifying Impact with Your Value!
            As a non-profit organization, Constantine African Foundation is committed to illuminating the continent with the light of 
            Christ and empowering communities to flourish. While we navigate this journey with limited financial resources, we firmly believe 
            in the transformative power of your unique contributions. Join us as we embark on an extraordinary voyage to make a profound impact 
            on the lives of countless individuals across Africa.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Our Guiding Beacon: Illuminating the Path to Prosperity
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
            Founded with a vision rooted in Christian values, the Constantine African Foundation seeks to initiate projects that foster 
            prosperity and opportunity across Africa. Despite being in the early stages of our journey, our unwavering determination 
            propels us forward, fueled by an unwavering commitment to making a meaningful difference.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Sailing Beyond Financial Constraints: Your Value Amplifies Our Impact
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        While our budget may be modest, we are far from being alone. Your unwavering support and dedication empower us to surmount 
            financial challenges and accomplish remarkable feats. With your invaluable contributions, we can amplify our impact and 
            transform lives across the continent.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        A Mission Without Borders: Extending Our Reach to Two African Nations
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Our mission transcends borders, extending its reach to two distinct African nations. Our team of five dedicated missionaries 
            is already making a tangible difference on the ground, tirelessly working to uplift communities and foster hope. Additionally, 
            we allocate an annual grant to further our mission, ensuring that our impact is felt far and wide.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Career Opportunities: Transforming Individuals and Communities
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Constantine African Foundation offers career opportunities that are not just professionally fulfilling but also transformative 
            for individuals and communities across Africa. Join us as we embark on this inspiring voyage, guided by the Lord's name and 
            divine blessings. Together, with God's grace, we shall forge a brighter future for all.
        </p>
      </motion.div>

      <motion.div
        className="text-left mx-auto p-10 max-w-5xl text-darkgren rounded-lg shadow-lg my-10"
        initial="hidden"
        animate="visible"
        variants={textVariants}
      >
        <h1 className="text-4xl text-center font-semibold text-darkgren">
        Together, We Can Make a Lasting Impact
        </h1>
        <p className="text-lg leading-relaxed mt-4 font-sans text-justify">
        Your contributions, no matter how big or small, have the power to fuel our journey and achieve extraordinary outcomes. We invite 
            you to be a part of this transformative odyssey, spreading the light and love of Christ to all corners of Africa. Join us as we 
            embark on this inspiring voyage, making a lasting impact that transcends borders and reshapes lives.
            With your unwavering support, we can illuminate the path to prosperity and empower countless individuals across Africa 
            to reach their full potential. 
        </p>
      </motion.div>

      <div className="mx-auto max-w-4xl my-16 px-6">
      {/* First Section */}
      <motion.div
        className="text-center bg-gradient-to-r from-darkgreen to-greenlight rounded-lg p-8 shadow-lg"
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <h2 className="text-3xl md:text-5xl font-bold text-white mb-4">
          "Together, we can make a world of difference."
        </h2>
      </motion.div>

      {/* career Button
      <div className="flex justify-center mt-10">
        <motion.button
          className="rounded-xl bg-darkgreen text-2xl px-5 py-2 mt-5 text-white cursor-pointer hover:bg-green-600 shadow-lg"
          onClick={() => setOpen(true)}
          whileHover="hover"
          variants={buttonVariants}
        >
          Apply Now!
        </motion.button>
        <Modal open={open} onClose={() => setOpen(false)}>
          <div className="text-center w-96">
            <Career />
            <button
              className="bg-darkgreen hover:bg-greendark text-white font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          </div>
        </Modal>
      </div> */}


        {/* Apply Now Button */}
        <div className="flex justify-center mt-10">
          <motion.button
            className="rounded-xl bg-darkgreen text-2xl px-5 py-2 mt-5 text-white cursor-pointer hover:bg-green-600 shadow-lg"
            onClick={() => setModalContent(<Career />)} // Set Career component as content
            whileHover="hover"
            variants={buttonVariants}
          >
            Apply Now!
          </motion.button>
        </div>


      <hr className="my-16 border-gray-300" />

      {/* Second Section */}
      <motion.div
        className="text-center p-8 text-darkgren rounded-lg shadow-lg"
        initial="hidden"
        animate="visible"
        variants={sectionVariants}
      >
        <h1 className="text-4xl md:text-5xl font-semibold text-gray-800 mb-4">
          Preparing for a Career with the Constantine African Foundation:
        </h1>
        <h2 className="text-3xl md:text-4xl font-medium text-blue-600 mb-8">
          Is It Your Next Step?
        </h2>
        <p className="text-lg md:text-xl text-gray-700 leading-relaxed font-sans text-justify">
          Constantine African Foundation presents an attractive salary package
          paired with outstanding benefits. Please note that opportunities in
          Ghana, Ivory Coast, Zambia and Uganda are exclusively available to individuals with Ghana, Ivory Coast, Zambia and Ugandan
          citizenship.
          <br />
          <br />
          If you are interested in submitting your resume and a letter of
          interest or cover letter, have any additional questions, or would
          like to explore other career opportunities with the Constantine
          African Foundation, please don't hesitate to reach out to us at:{" "}
          <a
            href="mailto:career@constantinefoundation.org"
            className="text-blue-500 hover:underline"
          >
            career@constantinefoundation.org
          </a>
          . We would be delighted to have a conversation with you.
        </p>
      </motion.div>
    </div>

    <motion.div
      className="mx-auto max-w-4xl p-8 bg-gray-50 text-darkgren rounded-lg shadow-lg my-16"
      initial="hidden"
      animate="visible"
      variants={sectionVariantss}
    >
      {/* Mission Paragraph */}
      <motion.p
        className="text-lg text-gray-700 leading-relaxed mb-6 font-sans text-justify" 
        variants={itemVariants}
      >
        If you feel a deep calling to leverage your talents and skills across Africa to bring people closer to Christ and His Church, consider embarking on a mission journey. Through immersive workshops, you can explore vocations like Byzantine drawing, Byzantine marble carving, and Byzantine chanting.
        <br />
        Discover the array of missionary opportunities waiting for you as we collaborate with our international mission partners. Take a step towards your mission{" "}
        <a className="hover:underline text-blue-500 font-semibold" href="#Ghana, Ivory Coast, Zambia and Uganda">
          click here
        </a>{" "}
        to explore the available options.
      </motion.p>

      {/* Headings */}
      <motion.h1
        className="text-3xl text-center font-bold text-gray-800 mb-4"
        variants={itemVariants}
      >
        Unlock Your Potential,
      </motion.h1>
      <motion.h2
        className="text-2xl text-center font-semibold text-blue-600 mb-6"
        variants={itemVariants}
      >
        Join Us in a Mission of Service, Sharing, and Inspiration.
      </motion.h2>

      {/* Additional Paragraphs */}
      <motion.p className="text-lg text-gray-700 leading-relaxed mb-4 font-sans text-justify" variants={itemVariants}>
        Are You Ready to Answer God's Divine Calling: 'Go Forth and Spread the Good News Worldwide.'
      </motion.p>
      <motion.p className="text-lg text-gray-700 leading-relaxed mb-4 font-sans text-justify" variants={itemVariants}>
        The Constantine African Foundation invites you to step into a life-changing experience where your skills, passions, and unwavering dedication can make a lasting impact.
      </motion.p>
      <motion.p className="text-lg text-gray-700 leading-relaxed mb-4 font-sans text-justify" variants={itemVariants}>
        Whether you're a seminarian, teacher, priest, professional, skilled tradesperson, engineer, or retiree, there is a place for you to share with us.
      </motion.p>
      <motion.p className="text-lg font-sans text-justify text-gray-700 leading-relaxed mb-4" variants={itemVariants}>
        Become a Constantine African Foundation volunteer missionary to help spread the Light and hope by teaching and delivering your experiences, practicing proclaiming the workshop, and sharing salvific messages throughout Africa.
      </motion.p>
      <motion.p className="text-lg font-sans text-justify text-gray-700 leading-relaxed mb-4" variants={itemVariants}>
        Be a part of all missionary job opportunities that have been approved and received the blessing of local hierarchs or the Metropolitan of the hosting country.
      </motion.p>
      <motion.p className="text-lg text-gray-700 font-sans text-justify leading-relaxed" variants={itemVariants}>
        Discover the available prospects for fostering unity among individuals within the Constantine African Foundation.
      </motion.p>
    </motion.div>

      {/* Volunteer Button
      <div className="flex justify-center mt-10">
        <motion.button
          className="rounded-xl bg-darkgreen text-2xl px-5 py-2 mt-5 text-white cursor-pointer hover:bg-green-600 shadow-lg"
          onClick={() => setOpen(true)}
          whileHover="hover"
          variants={buttonVariants}
        >
          Be Volunteer
        </motion.button>
        <Modal open={open} onClose={() => setOpen(false)}>
          <div className="text-center w-96">
            <Volunteer />
            <button
              className="bg-darkgreen hover:bg-greendark text-white font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          </div>
        </Modal>
      </div> */}



        {/* Be Volunteer Button */}
        <div className="flex justify-center mt-10">
          <motion.button
            className="rounded-xl bg-darkgreen text-2xl px-5 py-2 mt-5 text-white cursor-pointer hover:bg-green-600 shadow-lg"
            onClick={() => setModalContent(<Volunteer />)} // Set Volunteer component as content
            whileHover="hover"
            variants={buttonVariants}
          >
            Be Volunteer
          </motion.button>
        </div>

        {/* Modal */}
        <Modal open={modalContent !== null} onClose={() => setModalContent(null)}>
          <div className="text-center w-96">
            {modalContent} {/* Render the selected component */}
            <button
              className="bg-darkgreen hover:bg-greendark text-white font-semibold hover:text-white py-2 px-4 border border-darkgren hover:border-transparent rounded mt-4"
              onClick={() => setModalContent(null)}
            >
              Close
            </button>
          </div>
        </Modal>


      {/* Cards Section */}
      <div className="mx-auto p-5 max-w-6xl">
        {/* Card 1 */}
        <motion.div
          className="bg-white rounded-lg shadow-lg p-6 mb-10"
          initial="rest"
          whileHover="hover"
          variants={cardVariants}
        >
          <img src={asset1} alt="Byzantine Art Workshop" className="w-full h-auto rounded-md mb-4" />
          <h2 className="text-2xl font-semibold mb-2 text-darkgren" id="Ghana, Ivory Coast, Zambia and Uganda">Empowering Ghana, Ivory Coast, Zambia and Uganda's Youth through Byzantine Art</h2>
          <div className={`text-darkgren text-1xl ${showFullText1 ? '' : 'line-clamp-6'} max-md:max-w-full max-md:mt-10`}>
          <p className="text-base text-gray-600 font-sans text-justify">
          In order to revitalize and preserve the traditional art of Orthodox Byzantine art while creating opportunities 
          for income generation, a multifaceted approach is crucial. This initiative seeks to empower the younger generation
           by enhancing 
            existing youth ministry programs and introducing specialized training in the realm of Byzantine art. Within the
             context of Ghana, Ivory Coast, Zambia and Uganda, where a substantial portion of the population consists of youth grappling with unemployment, 
            this endeavor takes on added significance. By offering guidance and education in Orthodox Byzantine art, it not only 
            promotes cultural heritage but also equips individuals with the skills to generate income and secure a better future.
            This role is open to individuals from diverse backgrounds, including priests, monks, as well as those who are single 
            or married. Their collective effort will not only breathe new life into the age-old art form but also serve as a beacon 
            of hope for the youth, enabling them to thrive and contribute to their communities, all while preserving the rich 
            tradition of Orthodox Byzantine art.
          </p>
              <br />
              <h1>
              Missionary Job Description: 
              </h1>
              <p className="font-sans text-justify">
              To revitalize and preserve the traditional art of Orthodox Byzantine art and create income opportunities, 
              a multifaceted approach is essential. Our initiative empowers the youth in Ghana, Ivory Coast, Zambia and Uganda, combating unemployment.
               We enhance existing youth ministry programs and introduce specialized training in Byzantine art, promoting 
               cultural heritage and equipping individuals for a brighter future.
              This role welcomes diverse backgrounds - priests, monks, single, or married individuals. Together, we breathe 
              new life into this ancient art, offering hope to the youth while preserving tradition.
              </p>
              <h1> <br />
              Demographic Notes: 
              </h1>
              <p className="font-sans text-justify">
              In Ghana, Ivory Coast, Zambia and Uganda at main office, the Constantine Foundation finds inspiration in Byzantine art but realizes the need 
              for standardization. They aim to pioneer the infusion of African elements, aiming to be a first in East Africa.
               This harmonious blend not only enriches cultural diversity but also seeks to establish a uniform and standardized
                expression of Byzantine art, uniting tradition with innovation, and paving the way for a unique artistic journey
                 in the heart of Africa.
              </p>
            <p className="mt-4 font-sans text-justify">
            To Apply: Click below to submit a Missions Inquiry if you are interested in this opportunity. For questions, please 
            contact our Missionary Director, at Volunteer@constantinefoundation.org 
            Get ready for the upcoming workshop scheduled for the late 2024 to early 2025 period! Registration is now open.
            </p>
            </div>
          {!showFullText1 && (
            <button
              onClick={() => setShowFullText1(true)}
              className="w-[25%] bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded mt-4"
            >
              Read More
            </button>
          )}
        </motion.div>

        {/* Card 2 */}
        <motion.div
          className="bg-white rounded-lg shadow-lg p-6 mb-10"
          initial="rest"
          whileHover="hover"
          variants={cardVariants}
        >
          <img src={asset2} alt="Byzantine Marble Carving" className="w-full h-auto rounded-md mb-4" />
          <h2 className="text-2xl font-semibold mb-2 text-darkgren">Reviving Byzantine Marble Carving: Sustaining Tradition and Creating Income Opportunities</h2>
          <div className={`text-darkgren text-1xl font-sans text-justify ${showFullText2 ? '' : 'line-clamp-6'} font-sans text-justify max-md:max-w-full max-md:mt-10`}>
            Reviving the ancient art of Byzantine marble carving is crucial for preserving tradition and generating income. To achieve this, fostering 
            a thriving market is essential. By encouraging individuals to both learn and practice this craft, we can ensure its survival.
            Empowering artisans and artists to create beautiful Byzantine marble pieces and connecting them with potential buyers will breathe new life 
            into this art form. This not only helps artisans maintain a sense of cultural heritage but also enables them to earn a substantial income.
            Moreover, in regions like Ghana, Ivory Coast, Zambia and Uganda, where unemployment among youth is a pressing issue, such initiatives can make a significant difference.
             By offering training and support, we can revitalize Byzantine marble carving and ensure its continued legacy while providing economic 
             opportunities for those involved. Whether you're a monk, priest, single, or married, you can play a pivotal role in this revitalization effort.             
              <br /> <br />
              <h1>
              Missionary Job Description: Reviving Byzantine Marble Carving
              </h1>
              Reviving the ancient art of Byzantine marble carving is vital for tradition preservation and income generation. 
              Fostering a thriving market is essential. Encouraging individuals to learn and practice this craft is key to its survival.
              Empowering artisans and artists to create beautiful Byzantine marble pieces and connecting them with buyers rejuvenates 
              this art form. This sustains cultural heritage and provides substantial income.
              In regions like Ghana, Ivory Coast, Zambia and Uganda, where youth unemployment is pressing, such initiatives are impactful. By offering training 
              and support, we revitalize Byzantine marble carving, ensuring its legacy and creating economic opportunities. Monks, 
              priests, singles, or married individuals can all play a pivotal role in this revitalization effort.
              <p>
              To Apply: Click below to submit a Missions Inquiry if you are interested in this opportunity. For questions, 
              please contact our Missionary Director, at Volunteer@constantinefoundation.org 
              Get ready for the upcoming workshop scheduled for the late 2024 to early 2025 period! Registration is now open.
              </p>
            </div>
          {!showFullText2 && (
            <button
              onClick={() => setShowFullText2(true)}
              className="w-[25%] bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded mt-4"
            >
              Read More
            </button>
          )}
        </motion.div>

        {/* Card 3 */}
        <motion.div
          className="bg-white rounded-lg shadow-lg p-6 mb-10"
          initial="rest"
          whileHover="hover"
          variants={cardVariants}
        >
          <img src={asset3} alt="Byzantine Chanting" className="w-full h-auto rounded-md mb-4" />
          <h2 className="text-2xl font-semibold mb-2 text-darkgren">Reviving Byzantine Chanting for Generations</h2>
          <div className={`text-darkgren text-1xl font-sans text-justify ${showFullText3 ? '' : 'line-clamp-6'} font-sans text-justify max-md:max-w-full max-md:mt-10`}>
              Rekindle the ancient art of Byzantine chanting and find profound meaning in your voice. Our seminars 
              offer a unique opportunity for individuals from diverse backgrounds, whether you're a priest, a monk, 
              single, or married. By immersing yourself in this sacred tradition, you not only add spiritual richness 
              to your own life but also bring new vitality to our Church, all in the name of Jesus.
              This collective effort isn't just about preserving tradition; it's about revitalizing it. Together, 
              we breathe fresh life into this timeless art form, serving as a beacon of hope, especially for the 
              younger generation. By learning and embracing the intricacies of Orthodox Byzantine chanting, we 
              empower our youth to thrive and actively contribute to their communities, while safeguarding a 
              heritage that has endured for centuries. 
              Join us on this transformative journey, and be a part of something truly meaningful and enduring.
              <br />
              <br />
              <h1>
              Missionary Byzantine Chanting Instructor
              </h1>
              Are you a passionate advocate of Byzantine chanting and its spiritual significance? Join our mission as a 
              Byzantine Chanting Instructor, where you'll share this ancient art form with diverse audiences, including 
              priests, monks, singles, and married individuals. Your role goes beyond tradition preservation; it's about 
              reinvigorating it. You'll collaborate with a community dedicated to breathing new life into this timeless 
              practice, becoming a beacon of hope, especially for our youth.
              As a Missionary Byzantine Chanting Instructor, you'll impart the intricacies of Orthodox Byzantine chanting, 
              empowering the next generation to thrive and contribute to their communities. Your mission is to preserve a 
              heritage that has endured for centuries, bringing profound meaning to both your life and the Church, all in 
              the spirit of Jesus. Join us in this transformative journey, contributing to something enduring and truly meaningful.
              <br /><br />
              <h1>
              Demographic Notes: 
              </h1>
              <p>
              The Church in Ghana, Ivory Coast, Zambia and Uganda has been influenced by Byzantine style music and lacks standardization. There has also 
              been a Protestant influence on their music in some communities. The Metropolitan would like to introduce 
              African elements (the drum) and have standardized music for the Metropolis.
              </p>
              <p>
              To Apply: Click below to submit a Missions Inquiry if you are interested in this opportunity. For questions, 
              please contact our Missionary Director, at Volunteer@constantinefoundation.org 
              Get ready for the upcoming workshop scheduled for the late 2024 to early 2025 period! Registration is now open.
              </p>
            </div>
          {!showFullText3 && (
            <button
              onClick={() => setShowFullText3(true)}
              className="w-[25%] bg-transparent hover:bg-darkgreen text-darkgreen font-semibold hover:text-white py-2 px-4 border border-darkgreen hover:border-transparent rounded mt-4"
            >
              Read More
            </button>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default Careers;