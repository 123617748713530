import React, { useEffect } from "react";
import { motion } from "framer-motion";
//import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
//import { url } from "../../data";
import boxb1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxb2 from "../../assets/Headers/SVG/Asset_3.svg";
import axios from "axios";
import { useState } from "react";



const Blog =    () => {
  const [Data, setData]= useState(null)

const url = process.env.REACT_APP_API_URL + `/blogs?populate=*`;
const headers = {
  "Authorization":"bearer" + process.env.REACT_APP_API_TOKEN
}
console.log(url,'url')

useEffect(() => {
  const fetchData =  async () => {

    const {data} =   await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },

    })
    console.log(data?.data,'blog error')

    setData(data?.data  )

  console.log(Data,' Data')
}

fetchData()

},[url])

    return (
      <div className="flex mt-0 z-10 flex-col relative text-center bg-gradient-to-b from-greenlight to-greendark">
           <motion.div className="flex mt-10 z-10 flex-col w-[25%] h-[8%]">
              <motion.img
                initial={{
                  x: 50,
                  y: 50,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.7,
                    type: "easy",
                  },
                }}
                className=" absolute -z-10 lg:bottom-[80%] lg:left-[21%] lg:h-[10%] md:bottom-[87%] md:left-[21%] md:h-[8%] bottom-[91%] left-[21%] h-[4%]"
                alt="box"
                src={boxb2}
              />
              <motion.img
                initial={{
                  x: -50,
                  y: -50,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.7,
                    type: "easy",
                  },
                }}
                className=" absolute -z-10 lg:bottom-[75%] lg:left-[22%] lg:h-[10%] md:bottom-[83%] md:left-[22.3%] md:h-[8%] bottom-[93%] left-[19%] h-[4%]"
                alt="box"
                src={boxb2}
              />
              <motion.img
                initial={{
                  x: -50,
                  y: 50,
                  opacity: 0,
                }}
                whileInView={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.7,
                    type: "easy",
                  },
                }}
                className=" absolute -z-10 lg:bottom-[72%] lg:left-[19.7%] lg:h-[10%] md:bottom-[81%] md:left-[19.3%] md:h-[8%] bottom-[90%] left-[16.2%] h-[4%]"
                alt="box"
                src={boxb1}
              />
          </motion.div>

        <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem]  text-white">
          Latest Blogs
        </p>
        <motion.p
          initial={{
            y: 10,
            scale: 0.1,
          }}
          whileInView={{
            y: 0,
            scale: 1,
            transition: {
              duration: 0.8,
              bounce: 0.1,
              type: "easy",
            },
          }}
          viewport={{ once: true }}
          className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] text-white mb-3"
        >
          Read and get in touch with our curent storyies
        </motion.p>
 <div>
 <>
            {Data?.length > 0 ? (
              <div className="flex w-full md:flex-wrap lg:flex-nowrap flex-wrap gap-10 mb-20 m-[5%]">
                <div
                  className={`w-full ${
                    Data.length > 1 ? "md:w-3/6" : "w-full"
                  } flex flex-col md:flex-row   md:gap-10 gap-3 md:items-center items-start`}
                >
                  <motion.p
                    viewport={{ once: true }}
                    initial={{
                      y: 0,
                      opacity: 0,
                      scale: 0,
                    }}
                    whileInView={{
                      y: 0,
                      scale: 1,
                      opacity: 1,
                      transition: {
                        duration: 0.5,
                        bounce: 0.1,
                        type: "easy",
                      },
                    }}
                    className=" md:h-[17rem] lg:w-[20rem] md:w-[28rem] h-[13rem] w-[13rem] overflow-hidden bg-gray rounded-3xl flex-none "
                  >
                    <img
                      alt="blogImage"
                      src={process.env.REACT_APP_API_IMAGE_URL + Data[0]?.attributes?.imageUrl?.data[0].attributes?.url}
                      className=" w-full h-full object-cover"
                    />
                  </motion.p>
                  <div className=" text-left w-full">
                    <motion.p
                      viewport={{ once: true }}
                      initial={{
                        y: 100,
                        opacity: 0,
                      }}
                      whileInView={{
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        transition: {
                          duration: 0.5,
                          bounce: 0.1,
                          type: "easy",
                        },
                      }}
                      className="text-[1rem] md:text-[1.2rem] w-full mb-1 md:mb-4 text-white"
                    >
                      {Data[0]?.attributes.title}
                    </motion.p>
                    <motion.p
                      viewport={{ once: true }}
                      initial={{
                        y: 100,
                        opacity: 0,
                      }}
                      whileInView={{
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        transition: {
                          duration: 0.5,
                          bounce: 0.1,
                          type: "easy",
                        },
                      }}
                      className="pr-32 text-white font-sans text-justify text-[0.8rem] md:text-[1rem] line-clamp-3 "
                    >
                      {Data[0]?.attributes.description}
                    </motion.p>
                    <motion.div
                      viewport={{ once: true }}
                      initial={{
                        y: 100,
                        opacity: 0,
                      }}
                      whileInView={{
                        y: 0,
                        scale: 1,
                        opacity: 1,
                        transition: {
                          duration: 0.5,
                          bounce: 0.1,
                          type: "easy",
                        },
                      }}
                      className="w-full flex justify-start mb-5 md:mt-10 text-white font-bold  "
                    >
                      <Link
                        to={`/blogs/${Data[0]?.id}`}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <div className="font-sans bg-buttongreen rounded-xl px-3 py-2 text-[0.8rem] md:text-[1rem]  ">
                          Read More
                        </div>
                      </Link>
                    </motion.div>
                  </div>
                </div>



                  {Data.length > 1 && (
                  <div className=" w-full sm:w-3/6">
                    {Data.map((blog) => {

                        return (
                          <Link
                            key={blog.id}
                            to={`/blogs/${Data.id}`}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            <motion.div
                              viewport={{ once: true }}
                              initial={{
                                x: 100,
                                opacity: 0,
                              }}
                              whileInView={{
                                x: 0,
                                scale: 1,
                                opacity: 1,
                                transition: {
                                  duration: 0.5,
                                  bounce: 0.1,
                                  type: "easy",
                                  delay: 0.4 + blog.id * 0.1,
                                },
                              }}
                              className="flex md:gap-4 gap-1 items-center mb-4"
                            >
                              <div className="md:h-20 md:w-20 h-14 w-14 overflow-hidden bg-gray rounded-lg flex-none">
                                <img
                                  alt="blogImage"
                                  src={process.env.REACT_APP_API_IMAGE_URL + blog.attributes?.imageUrl?.data[0].attributes?.url}
                                  className=" w-full h-full object-cover"
                                />
                              </div>
                              <p className=" w-full text-white font-sans text-left  text-[0.8rem] md:text-[1rem] ">
                                {blog.attributes.title}
                              </p>
                            </motion.div>
                          </Link>
                        );

                    })}
                  </div>
                )}
              </div>
            ) : (
              <div>ther is no blog yet</div>
            )}
          </>
 </div>

        <div className="md:w-4/5 w-11/12 m-auto h-1  bg-black"></div>
      </div>
    );

};

export default Blog;
