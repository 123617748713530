import * as React from "react";
import DownloadCard from "./DownloadCard";
import anual from "../../assets/report Finince.gif.mp4";
import latest from "../../assets/Latest Audited Financials.gif.mp4";
import { useEffect, useState } from "react";

function DownloadSection() {
  const downloads = [
    {
      imageUrl: anual,
      title: "Annual Report",
      buttonText: "Download"
    },
    {
      imageUrl: latest,
      title: "Latest Audited Financials",
      buttonText: "Download"
    }
  ];

  const [latestReportId, setLatestReportId] = useState(null);

  useEffect(() => {
    const fetchLatestReport = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/subscriptions?populate=*`);
        const { data } = await response.json();
        if (data && data.length > 0) {
          setLatestReportId(data[0].id); // Assuming the first report is the latest
        }
      } catch (error) {
        console.error("Error fetching the latest report:", error.message);
      }
    };

    fetchLatestReport();
  }, []);

  return (
    <div className="flex overflow-hidden flex-col justify-center items-center px-16 py-6 max-md:px-5 max-md:py-24">
      <div className="max-w-full w-[868px]">
        <div className="flex gap-5 max-md:flex-col">
          {downloads.map((download, index) => (
            <div key={index} className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <DownloadCard
                imageUrl={download.imageUrl}
                title={download.title}
                buttonText={download.buttonText}
                reportId={latestReportId} // Pass the latest report ID here
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default DownloadSection;
