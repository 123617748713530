// import DetailHead from "../../components/campaign/detailHeader";
// import React from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useState } from "react";
// import { useEffect } from "react";
// import axios from "axios";
// import Donation from "../Contactus/Donation";
// import x from "../../assets/Logo/bglo.png";
// import NextCapmaign from "./NextCampaign";
// import { Link } from "react-router-dom";
// import { motion } from "framer-motion";
// import DontationBacground from "../../assets/Constantine_Donate_rm.png";

// const CampainDetail = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const [Data, setData] = useState(null);

//   const url = `${process.env.REACT_APP_API_URL}/campaigns/${id}?populate=*`;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const { data } = await axios({
//           method: "get",
//           url: url,
//           headers: {
//             Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
//           },
//         });

//         setData(data?.data);
//       } catch (error) {
//         console.error("Error fetching blog data:", error.message);
//       }
//     };

//     fetchData();
//   }, [url]);

//   return (
//     <>
//       <div className="grid md:grid-cols-3 lg:md:grid-cols-4 bg-darkgren pt-12">
//         <div className="p-8 col-span-3 bg-darkgren">
//           <div className="text-white text-4xl bg-zinc-300 pl-5 pr-16 py-5">
//             {Data?.attributes?.name}
//           </div>
//           <div className="w-full mt-8 flex flex-col md:flex-row gap-5">
//             <div className="flex-1">
//               <img
//                 loading="lazy"
//                 src={`${process.env.REACT_APP_API_IMAGE_URL}${Data?.attributes?.imageURL?.data[0].attributes?.url}`}
//                 className="object-contain w-full"
//               />
//             </div>
//             <div className="flex-1">
//               <div
//                 style={{ backgroundImage: `url(${x})` }}
//                 className="text-white text-justify font-sans text-xl bg-zinc-300 px-7"
//               >
//                 {Data?.attributes?.right_desc}
//               </div>
//             </div>
//           </div>
//           <div className="text-white text-justify font-sans text-xl bg-zinc-300 mt-1 p-5">
//             {Data?.attributes?.description}
//           </div>
//           {/* <Donation /> */}
//           <div className=" flex flex-col md:flex-row lg:flex-row">
//           <div className="flex w-full gap-10  relative py-[5rem]">
//             <motion.div
//               initial={{
//                 x: -400,
//                 opacity: 0,
//               }}
//               viewport={{ once: true }}
//               whileInView={{
//                 x: 0,
//                 scale: 1,
//                 opacity: 1,
//                 transition: {
//                   duration: 0.8,
//                   bounce: 1,
//                   type: "easy",
//                 },
//               }}
//               className="lg:h-[20rem] lg:w-[38rem] md:h-[16rem] md:w-[5rem] h-[3rem] w-[2rem] bg-green rounded-3xl lg:p-10 md:p-5 p-3 text-left text-white lg:pt-0 md:pt-0 pt-32"
//             >
//               <p className="font-roboto font-bold md:text-[1.3rem] text-[1.1rem] my-5 md:mb-10">
//                 PLACE YOUE DONATION TODAY
//               </p>
//               <input type="text" placeholder="Please enter the amount to donate here" className="md:w-9/12 w-full mb-6 border-2 bg-green text-white border-white rounded-xl p-3"/>
//               <div className="md:w-6/12 w-9/12 mb-6 text-greendark text-center flex gap-2">
//                 <div className="bg-white rounded-xl p-3 w-3/12">10 $</div>
//                 <div className="bg-white rounded-xl p-3 w-3/12">50 $</div>
//                 <div className="bg-white rounded-xl p-3 w-6/12">100 $</div>
//               </div>
//               <div className="md:w-6/12 w-9/12  mb-6 border-2 border-white text-center rounded-xl p-3">
//                 <Link
//                   onClick={() => window.scrollTo(0, 0)}
//                   to={"/donate"}
//                   >
//                   Donate
//                 </Link>
//               </div>
//             </motion.div>
//           </div>
//           <img
//             src={DontationBacground}
//             alt="donation"
//             className="w-[40%] 4-[50%] ml-8"
//           />
//           </div>

//         </div>

//         <div className="p-4 md:p-8">
//           <NextCapmaign />
//         </div>
//       </div>
//     </>
//   );
// };

// export default CampainDetail;


import DetailHead from "../../components/campaign/detailHeader";
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import Donation from "../Contactus/Donation";
import x from "../../assets/Logo/bglo.png";
import NextCapmaign from "./NextCampaign";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import DontationBacground from "../../assets/Constantine_Donate_rm.png";

const CampainDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [Data, setData] = useState(null);

  const url = `${process.env.REACT_APP_API_URL}/campaigns/${id}?populate=*`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        setData(data?.data);
      } catch (error) {
        console.error("Error fetching campaign data:", error.message);
      }
    };

    fetchData();
  }, [url]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 bg-darkgren pt-12">
      <div className="p-6 md:p-8 col-span-3 bg-darkgren">
        {/* Campaign Title */}
        <div className="text-white text-2xl md:text-3xl lg:text-4xl bg-zinc-300 pl-4 md:pl-5 pr-6 py-4 rounded-lg">
          {Data?.attributes?.name}
        </div>

        {/* Campaign Details */}
        <div className="w-full mt-8 flex flex-col md:flex-row gap-6">
          {/* Campaign Image */}
          <div className="flex-1">
            <img
              loading="lazy"
              src={`${process.env.REACT_APP_API_IMAGE_URL}${Data?.attributes?.imageURL?.data[0].attributes?.url}`}
              className="object-cover w-full rounded-lg"
              alt="Campaign"
            />
          </div>

          {/* Campaign Description */}
          <div className="flex-1">
            <div
              style={{ backgroundImage: `url(${x})` }}
              className="text-white text-justify font-sans text-sm md:text-base lg:text-xl bg-zinc-300 px-5 md:px-7 py-4 rounded-lg"
            >
              {Data?.attributes?.right_desc}
            </div>
          </div>
        </div>

        {/* Full Campaign Description */}
        <div className="text-white text-justify font-sans text-sm md:text-base lg:text-xl bg-zinc-300 mt-4 p-5 rounded-lg">
          {Data?.attributes?.description}
        </div>

        {/* Donation Section */}
        <div className="flex flex-col md:flex-row items-center gap-6 mt-10">
          <motion.div
            initial={{ x: -400, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, type: "spring" }}
            className="flex flex-col bg-green text-white rounded-3xl p-5 lg:p-10 text-center w-full md:w-1/2"
          >
            <p className="font-roboto font-bold text-base md:text-lg lg:text-xl mb-4">
              PLACE YOUR DONATION TODAY
            </p>
            <input
              type="text"
              placeholder="Enter the amount to donate"
              className="w-full mb-4 border-2 bg-green text-white border-white rounded-lg p-3"
            />
            <div className="flex justify-around gap-2 mb-4">
              <div className="bg-white text-greendark rounded-lg px-4 py-2">10 $</div>
              <div className="bg-white text-greendark rounded-lg px-4 py-2">50 $</div>
              <div className="bg-white text-greendark rounded-lg px-4 py-2">100 $</div>
            </div>
            <div className="border-2 border-white text-center rounded-lg p-3 w-full">
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/donate"
                className="text-white"
              >
                Donate
              </Link>
            </div>
          </motion.div>
          <img
            src={DontationBacground}
            alt="Donation Background"
            className="w-full md:w-1/2 object-cover rounded-lg"
          />
        </div>
      </div>

      {/* Next Campaign Section */}
      <div className="p-4 md:p-8">
        <NextCapmaign />
      </div>
    </div>
  );
};

export default CampainDetail;