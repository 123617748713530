/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { SiGooglepay } from "react-icons/si";

const CustomerProfile = () => {
    const location = useLocation();
    const { state } = location || {};
    const { user, products } = state || {}; // Fallbacks if state is undefined

    // Debug Logs
    console.log('Location state:', location.state);
    console.log('User:', user);
    console.log('Products:', products);

    // Validate user structure
    const isUserArray = Array.isArray(user);

    // Calculate total price
    const totalPrice = products?.reduce((sum, product) => sum + (product.price ?? 0) * (product.quantity ?? 1), 0) ?? 0;

    const handleCheckout = () => {
        alert(`Total Price: $${totalPrice.toFixed(2)}\nProceeding to payment...`);
        // Add payment integration here
    };

    const handleGooglePay = () => {
        if (!totalPrice) {
          alert("Please enter a donation amount.");
          return;
        }
    
        if (!window.google) {
          alert("Google Pay script not loaded. Please try again later.");
          return;
        }
    
        const googlePayClient = new google.payments.api.PaymentsClient({ environment: "TEST" });
    
        const paymentDataRequest = {
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["AMEX", "MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "example",
                  gatewayMerchantId: "exampleMerchantId",
                },
              },
            },
          ],
          merchantInfo: {
            merchantName: "Constantine Foundation",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: totalPrice,
            currencyCode: "USD",
          },
        };
    
        googlePayClient
          .isReadyToPay({ allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods })
          .then((response) => {
            if (response.result) {
              googlePayClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
                console.log("Payment Success:", paymentData);
                alert("Thank you for your donation!");
              });
            } else {
              alert("Google Pay is not available.");
            }
          })
          .catch((err) => console.error("Error initializing Google Pay:", err));
      };

    return user ? (
        <div className="bg-white p-6 my-32 rounded-2xl shadow-2xl w-full md:w-[80%] mx-auto">
            <h2 className="text-3xl font-bold text-darkgreen">Customer Profile</h2>
            <div className="border-[1px] w-20 border-darkgreen my-3"></div>
            
            {/* {isUserArray ? (
                // If user is an array, iterate and display
                user.map((use, index) => (
                    <div key={index} className="mt-4">
                        <p className="text-lg font-semibold text-darkgreen">Name: {use.name ?? 'Unknown'}</p>
                        <p className="text-lg font-semibold text-darkgreen">Email: {use.email ?? 'Unknown'}</p>
                    </div>
                ))
            ) : (
                // If user is a single object
                <div className="mt-4">
                    <p className="text-lg font-semibold text-darkgreen">Name: {user.name ?? 'Unknown'}</p>
                    <p className="text-lg font-semibold text-darkgreen">Email: {user.email ?? 'Unknown'}</p>
                </div>
            )} */}

            <h2 className="text-2xl font-bold text-darkgreen mt-8">Your Products</h2>
            <div className="mt-4 space-y-2">
                {Array.isArray(products) && products.length > 0 ? (
                    products.map((product, index) => (
                        <div
                            key={index}
                            className="p-4 border rounded-lg shadow-sm flex justify-between items-center"
                        >
                            <p><strong>Name:</strong> {product.name ?? 'Unknown'}</p>
                            <p><strong>Price:</strong> ${product.price?.toFixed(2) ?? '0.00'}</p>
                            <p><strong>Quantity:</strong> {product.quantity ?? 1}</p>
                        </div>
                    ))
                ) : (
                    <p>No products available.</p>
                )}
            </div>

            <div className="mt-6">
                <h3 className="text-lg font-bold text-darkgreen">Total Price: ${totalPrice.toFixed(2)}</h3>
                <button
                    onClick={handleCheckout}
                    className="my-4 bg-darkgreen text-white py-2 px-6 rounded-lg hover:bg-green-700"
                >
                    Final Checkout
                </button>
                <button
              className="px-6 flex flex-row py-2 my-auto font-sans bg-black text-white rounded-md hover:opacity-75"
              onClick={handleGooglePay}
            >
              Pay with
              <SiGooglepay className="my-auto mx-2" size={30} />
            </button>
            </div>
        </div>
    ) : (
        <p className='m-32'>Loading customer data...</p>
    );
};

CustomerProfile.propTypes = {
    user: PropTypes.oneOfType([
        PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                email: PropTypes.string,
            })
        ),
    ]),
    products: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            price: PropTypes.number,
            quantity: PropTypes.number,
        })
    ),
};

export default CustomerProfile;
