import React, { useEffect, useState } from "react";
import ProgressBar from "../../components/ProgressBar";
import { motion } from "framer-motion";
import ProjectReport from "./projectReport";
import asset1 from "../../assets/Constantine Project Header copy.png";
import axios from "axios";
import { Link } from "react-router-dom";
import NextProject from "./NextProject";
import Doner from '../../components/donors';

export default function Project() {
  const [Data, setData] = useState(null);
  const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;
  const headers = {
    Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
  };

  console.log(url, "url");

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios({
        method: "get",
        url: url,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      console.log(data?.data, "project error");
      setData(data?.data);
    };
    fetchData();
  }, [url]);

  return (
    <div>
      {/* Header Section */}
      <div
        className="relative lg:h-[52rem] md:h-[35rem] h-[18rem] w-full overflow-hidden flex items-end"
        id="header"
      >
        <img
          src={asset1}
          alt="header"
          className="absolute inset-0 -z-10 w-full h-full object-cover object-right md:object-center"
        />
      </div>

      {/* Finished Projects Section */}
      <div className="bg-white py-10">
        {/* <h1 className="text-5xl text-darkgren text-center mb-10 ">Finished Projects</h1> */}
        <h1 className="text-5xl text-darkgren text-center mb-10 ">Our Projects</h1>
        {Data?.filter((g) => g.attributes?.status === "finished").map((g) => (
          <div className="mb-10 bg-white" key={g.id}>
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex h-auto md:p-8 w-full md:w-[30%]">
                <motion.div
                  className="rounded-full w-full h-[200px] md:h-auto bg-gray flex items-center justify-center overflow-hidden"
                  initial={{ x: 400, opacity: 0 }}
                  viewport={{ once: true }}
                  whileInView={{ x: 0, opacity: 1, transition: { duration: 2, bounce: 2 } }}
                >
                  <img
                    src={
                      process.env.REACT_APP_API_IMAGE_URL +
                      g.attributes?.left_img_1?.data[0].attributes?.url
                    }
                    alt=""
                    className="w-full h-full object-cover rounded-full"
                  />
                </motion.div>
              </div>
              <motion.div
                className="md:flex-grow text-darkgren p-4 lg:w-[70%] md:w-[70%] w-full"
                initial={{ x: -400, opacity: 0 }}
                viewport={{ once: true }}
                whileInView={{ x: 0, opacity: 1, scale: 0.98, transition: { duration: 2, bounce: 2 } }}
              >
                <div className="flex flex-col w-full p-2">
                  <h1 className="text-xl md:text-2xl font-bold">{g.attributes?.name}</h1>
                  <p className="mt-2 text-sm md:text-base text-justify">{g.attributes?.left_desc_1}</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                  <div className="flex flex-col justify-center">
                    <h5>Progress</h5>
                    <ProgressBar progress={g.attributes?.progress} />
                  </div>
                  <div className="flex justify-center items-end">
                    <div className="bg-buttongreen hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">
                      <Link
                        preventScrollReset={true}
                        to={`/projects/${g.id}`}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        ))}
      </div>

            {/* Main Section */}
            <div className="flex flex-col md:flex-col lg:flex-row bg-darkgren overflow-hidden">
        <div className="w-[100%] md:w-[100%] lg:w-[80%]">
          {/* <h1 className="bg-darkgren lg:text-5xl md:text-4xl text-3xl text-white text-center p-10">
            Unfinished Projects
          </h1> */}
          <h1 className="bg-darkgren text-3xl text-white text-center my-10">
            Currently in Progress Projects
          </h1>
          {Data?.filter((g) => g.attributes?.status === "unfinished").map(
            (g) => (
              <div className="bg-darkgren p-3" key={g.id}>
                <div className="flex flex-col md:flex-row w-full">
                  <div className="flex h-auto md:p-8 w-full md:w-[30%]">
                    <motion.div
                      className="w-full h-[200px] md:h-auto rounded-full bg-white flex items-center justify-center"
                      initial={{ x: 400, opacity: 0 }}
                      viewport={{ once: true }}
                      whileInView={{ x: 0, opacity: 1, transition: { duration: 2, bounce: 2 } }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_API_IMAGE_URL +
                          g.attributes?.left_img_1?.data[0].attributes?.url
                        }
                        alt="project"
                        className="w-full h-full rounded-full object-cover"
                      />
                    </motion.div>
                  </div>
                  <motion.div
                    className="md:flex-grow text-white p-4 lg:w-[70%] md:w-[70%] w-full"
                    initial={{ x: -400, opacity: 0 }}
                    viewport={{ once: true }}
                    whileInView={{ x: 0, opacity: 1, scale: 0.98, transition: { duration: 2, bounce: 2 } }}
                  >
                    <div className="p-4">
                      <h1 className="text-lg md:text-xl lg:text-2xl font-bold">{g.attributes?.name}</h1>
                      <p className="mt-2 text-[1rem] md:text-[1.2rem] lg:text-[1.4rem] font-sans text-justify">{g.attributes?.left_desc_1}</p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                      <div className="flex flex-col justify-center mb-3">
                        <h5>Progress</h5>
                        <ProgressBar progress={g.attributes?.progress} />
                      </div>
                      <div className="flex justify-center items-end">
                        <div className="bg-buttongreen hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">
                          <Link
                            preventScrollReset={true}
                            to={`/projects/${g.id}`}
                            onClick={() => window.scrollTo(0, 0)}
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            )
          )}
        </div>

        {/* Donor Section */}
        <div className="w-[100%] md:w-[100%] lg:w-[20%] mt-10 mx-8 md:mx-32 lg:mx-3">
          <Doner />
        </div>
      </div>

      {/* <ProjectReport /> */}
    </div>
  );
}
