import React from 'react';
import Donation from "../../Contactus/Donation";
import x from "../../../assets/Logo/bglo.png";
import NextBlog from "./NextBlog";
import RecentCard from "../../../components/RecentCard";

const Body = ({Data}) => {
  return (
    <>
        <div className="flex lg:flex-row md:flex-col flex-col bg-darkgren">
          <div className=' col-span-2 pt-20 sm:row-span-1'>
          <div className=" w-full text-white text-4xl self-center text-center max-w-full pt-10 pb-3 px-16 items-start max-md:pl-6 max-md:pr-5">
              {Data?.attributes?.title}
          </div>
          <div className="w-full mt-9 max-md:max-w-full">
            <div className="gap-5 flex max-md:flex-col max-md: max-md:gap-0">
              <div className="flex flex-col  w-[30%] max-md:w-full max-md:ml-0">
              <img
                loading="lazy"
                src={`${process.env.REACT_APP_API_IMAGE_URL + Data?.attributes?.imageUrl.data[0].attributes?.url}`}
                className="object-contain  max-md:mt-10 aspect-w-1 aspect-h-1"
              />
              </div>
              <div className=" bg-darkgren flex px-2 md:px-0 lg:px-0 flex-col  w-[61%] ml-5 max-md:w-full max-md:ml-0">
                <div className="text-white text-[1rem] md:text-[1.2rem] lg:text-[1.5rem] grow text-justify font-sans  w-full py-2 max-md:max-w-full max-md:mt-10 max-md:px-1">
                {Data?.attributes?.right_desc}
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="xl:w-[100%] md:w-[100%] lg:w-[100%] pt-28 sm:row-span-1">
            {/* <NextBlog   /> */}
            <RecentCard />
          </div>
          </div>

    </>
  );
};

export default Body;

