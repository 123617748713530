//import ConstantinLogo from "../../assets/ConstantionLogoColor.png";
//import { useEffect, useRef } from "react";
//import Lottie from "lottie-react";
//import box1 from "../../assets/Headers/SVG/Asset_1.svg";
import boxa1 from "../../assets/Headers/SVG/Asset_2.svg";
import boxa2 from "../../assets/Headers/SVG/Asset_3.svg";
import DontationBacground from "../../assets/Contat Us Page/SVG/Asset 1.svg";
import ConstantinLogoOnly from "../../assets/home aboutus tree.svg";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import React from "react";


const About = () => {
  return (
<div className="flex mt-0 z-10 flex-col relative text-center">
      <motion.div className="flex mt-0 z-10 flex-col w-[25%] h-[8%]" >
            <motion.img
              initial={{
                x: 50,
                y: 50,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className="absolute -z-10 lg:bottom-[95%] lg:left-[28%] lg:h-[8%] md:bottom-[95%] md:left-[28%] md:h-[8%] bottom-[95%] left-[20%] h-[8%]"
              alt="box"
              src={boxa2}
            />
            <motion.img
              initial={{
                x: -50,
                y: -50,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className="absolute -z-10 lg:bottom-[91%] lg:left-[29%] lg:h-[8%] md:bottom-[91%] md:left-[29%] md:h-[8%] bottom-[91%] left-[22%] h-[8%]"
              alt="box"
              src={boxa2}
            />
            <motion.img
              initial={{
                x: -50,
                y: 50,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                y: 0,
                opacity: 1,
                transition: {
                  duration: 0.7,
                  type: "easy",
                },
              }}
              className="absolute -z-10 lg:bottom-[89%] lg:left-[26.7%] lg:h-[8%] md:bottom-[89%] md:left-[26.5%] md:h-[8%] bottom-[89%] left-[17.7%] h-[8%]"
              alt="box"
              src={boxa1}
            />
          </motion.div>
          <p className="lg:text-[3.5rem] md:text-[2rem] text-[2rem]  text-greenshade">About Us</p>
      <motion.p
        initial={{
          y: 10,
          scale: 0.1,
        }}
        whileInView={{
          y: 0,
          scale: 1,
          transition: {
            duration: 0.8,
            bounce: 0.1,
            type: "easy",
          },
        }}
        viewport={{ once: true }}
        className="lg:text-[1.5rem] md:text-[1rem] text-[1rem] text-green mb-3 md:mb-5 capitalize "
      >
        
        Welcome to Constantine africa foundation
      </motion.p>

      <div className="flex w-full gap-10 md:mb-20 mb-10">
        <div className=" w-2/5 relative hidden md:block">
          
          <motion.img
            viewport={{ once: true }}
            initial={{
              x: 0,
              opacity: 0,
              scale: 0,
            }}
            whileInView={{
              x: 0,
              scale: 1,
              opacity: 1,
              transition: {
                duration: 0.5,
                bounce: 0.1,
                delay: 0.6,
                type: "easy",
              },
            }}
            src={ConstantinLogoOnly}
            alt="logo"
            className="w-2/3 ml-[25%] "
          />

          <motion.img
            initial={{
              x: -100,
              opacity: 0,
            }}
            whileInView={{
              x: 0,
              scale: 1,
              opacity: 1,
              transition: {
                duration: 0.5,
                bounce: 0.1,
                delay: 0.5,
                type: "easy",
              },
            }}
            viewport={{ once: true }}
            src={DontationBacground}
            className="w-2/3 sm:w-3/4 lg:w-[80%] left-[19.5%] top-[26%] absolute sm:top-[16%] sm:left-[17%] lg:top-[0%] lg:left-[22.8%] -z-10"
          />          
          
        </div>
        <div className=" md:w-2/4 w-full text-left flex flex-col justify-center">
          <motion.p
            viewport={{ once: true }}
            initial={{
              y: 100,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              scale: 1,
              opacity: 1,
              transition: {
                duration: 0.5,
                bounce: 0.1,
                type: "easy",
              },
            }}
            className="lg:text-[2.5rem] md:text-[1.5rem] text-[2rem] ml-3  text-greenshade leading-[2.2rem] mb-1 md:mb-5"
          >
            Together We Change
          </motion.p>
          <motion.p
            viewport={{ once: true }}
            initial={{
              y: 20,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              scale: 1,
              opacity: 1,
              transition: {
                duration: 0.5,
                bounce: 0.1,
                type: "easy",
              },
            }}
            className="w-auto text-green font-sans font-bold text-justify mx-6 lg:text-[1rem] md:text-[1rem] text-[0.8rem]"
          >
            Constantine African Foundation was established with a goal to
            advocate for the rights of marginalized and vulnerable populations
            to access quality education and healthcare in Ghana,Ivory Coast, Zambia, Uganda and all of
            Africa.
            <br /> The foundation aims to promote and support local cultures and
            morals in Africa and strengthen cooperation in humanitarian fields
            among all African countries.
          </motion.p>
          <motion.div
            viewport={{ once: true }}
            initial={{
              y: 20,
              opacity: 0,
            }}
            whileInView={{
              y: 0,
              scale: 1,
              opacity: 1,
              transition: {
                duration: 0.5,
                bounce: 0.1,
                type: "easy",
              },
            }}
            className="w-full flex justify-end mt-10 text-white font-bold  "
          >
            <Link to={"/about"} onClick={() => window.scrollTo(0, 0)}>
              <div className="font-sans bg-buttongreen rounded-3xl px-3 py-2 text-[0.8rem] md:text-[1rem] ">
                Read More
              </div>
            </Link>
          </motion.div>
        </div>
      </div>

      <div className="md:w-4/5 w-11/12 m-auto h-1  bg-black"></div>
    </div>
  );
};

export default About;
