/* global google */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Donate from "../components/donors";
import header from "../assets/donation  copy.png";
import DonationProtected from "./DonationProtected.jsx";
import { SiGooglepay } from "react-icons/si";
import { useLocation } from "react-router-dom";

const Donater = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    amount: "",
    purpose: "",
    showName: "Yes",
  });
  const location = useLocation();
  const { project } = location.state || {}; // Destructure project data
  const [purposes, setPurposes] = useState([]); // State for purposes list

  // Fetch purposes from Strapi
  useEffect(() => {
    const fetchPurposes = async () => {
      try {
        const url = process.env.REACT_APP_API_URL + `/projects?populate=*`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        });

        const purposesData = response.data.data.map((purpose) => ({
          id: purpose.id,
          name: purpose.attributes.name,
        }));
        setPurposes(purposesData);
      } catch (error) {
        console.error("Error fetching purposes:", error);
      }
    };

    fetchPurposes();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      data: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        amount: formData.amount,
        purpose: formData.purpose,
        showName: formData.showName === "Yes",
      },
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/donors`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });

      if (response.status !== 200) {
        throw new Error(response.data.error.message || "Something went wrong");
      }

      const result = response.data;
      console.log("Success:", result);
      alert("Donation form submitted successfully");
      // navigate("/auth");

      // Uncomment to redirect to payment gateway with amount as a query parameter
      const paymentUrl = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}?amount=${formData.amount}`;
      window.location.href = paymentUrl;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGooglePay = () => {
    if (!formData.amount) {
      alert("Please enter a donation amount.");
      return;
    }

    if (!window.google) {
      alert("Google Pay script not loaded. Please try again later.");
      return;
    }

    const googlePayClient = new google.payments.api.PaymentsClient({ environment: "TEST" });

    const paymentDataRequest = {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: "CARD",
          parameters: {
            allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
            allowedCardNetworks: ["AMEX", "MASTERCARD", "VISA"],
          },
          tokenizationSpecification: {
            type: "PAYMENT_GATEWAY",
            parameters: {
              gateway: "example",
              gatewayMerchantId: "exampleMerchantId",
            },
          },
        },
      ],
      merchantInfo: {
        merchantName: "Constantine Foundation",
      },
      transactionInfo: {
        totalPriceStatus: "FINAL",
        totalPrice: formData.amount,
        currencyCode: "USD",
      },
    };

    googlePayClient
      .isReadyToPay({ allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods })
      .then((response) => {
        if (response.result) {
          googlePayClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
            console.log("Payment Success:", paymentData);
            alert("Thank you for your donation!");
          });
        } else {
          alert("Google Pay is not available.");
        }
      })
      .catch((err) => console.error("Error initializing Google Pay:", err));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log("Submit donation form logic");
  // };

  return (
    <React.Fragment>
      {/* Header Section */}
      <div
        className="relative lg:h-[52rem] md:h-[35rem] h-[18rem] w-full overflow-hidden flex items-end"
        id="header"
      >
        <img
          src={header}
          alt="header"
          className="absolute inset-0 -z-10 w-full h-full object-cover object-right md:object-center"
        />
      </div>
      <div className="text-center mt-10 text-2xl text-darkgreen">
        <h1>"Be the Light of Change – Support the Constantine Foundation Today!"</h1>
      </div>
      <div className="flex flex-col md:flex-col lg:flex-row">
        <section className="w-[100%] md:w-[100%] lg:w-[70%] p-6 mx-auto md:mx-auto lg:mx-10 bg-green rounded-md shadow-md my-20">
          <h1 className="text-xl font-bold text-white capitalize dark:text-white">Donation form</h1>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="firstName">
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="amount">
                  Amount
                </label>
                <input
                  id="amount"
                  type="number"
                  value={formData.amount}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                />
              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="purpose">
                  Purpose of donation
                </label>
                <select
                  id="purpose"
                  value={formData.purpose || project} // Use {project} if no value is selected
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                >
                  <option value="" disabled>
                    Select purpose
                  </option>
                  {purposes.map((purpose) => (
                    <option key={purpose.id} value={purpose.name}>
                      {purpose.name}
                    </option>
                  ))}
                </select>

              </div>
              <div>
                <label className="text-white dark:text-gray-200" htmlFor="showName">
                  Would you like to show your name on the website?
                </label>
                <select
                  id="showName"
                  value={formData.showName}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                >
                  <option>Yes</option>
                  <option>No</option>
                </select>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <button className="px-6 py-2 bg-white leading-5 text-primary hover:bg-primary hover:text-white transition-colors duration-200 transform bg-pink-500 rounded-md hover:bg-pink-700 focus:outline-none focus:bg-gray-600">
                Donate
              </button>
            </div>
          </form>
          <div className="mt-4">
            <button
              className="px-6 flex flex-row py-2 my-auto font-sans bg-black text-white rounded-md hover:opacity-75"
              onClick={handleGooglePay}
            >
              Donate with
              <SiGooglepay className="my-auto mx-2" size={30} />
            </button>
          </div>
        </section>
        <div className="mt-[4rem] w-[100%] md:w-[100%] lg:w-[30%] p-6 mx-auto md:mx-auto lg:mx-10">
          <Donate />
        </div>
      </div>
      <div className="text-center mb-10">
        <DonationProtected />
      </div>
    </React.Fragment>
  );
};

export default Donater;
